import React, { useContext, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  Tooltip,
  Paper,
  IconButton,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Modal,
  CircularProgress,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import ErrorIcon from "@mui/icons-material/Error";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PeopleIcon from "@mui/icons-material/People";
import AlarmIcon from "@mui/icons-material/Alarm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ImageIcon from "@mui/icons-material/Image";

import { QuizContext, QuizProvider } from "./contexts/Quiz";

import Layout from "Layout/EditPageLayout";
import { Textfield as TextField } from "components/Textfield";
import { CustomButton as Button } from "components/Button";
import classes from "./styles/LiveQuizStyles";
import { QuizTemplate } from "template/Quiz";
import QuizEditor from "./components/Editor";
import QuizType from "./components/QuizType";
import QuizSlide from "./components/QuizSlide";
import { SnackBarContext, SnackBarProvider } from "./contexts/SnackBar";
import { GoBackModal, LoadingModal, UploadImageModal } from "components/Modal";
import { GET_QuizData, POST_quizData } from "utils/api/Quiz";
import * as API from "utils/api/Quiz";

// import { useCookies } from "react-cookie";
import AWS from "utils/aws";
import logo from "assets/images/wLiveLogo(Red).png";

const QUIZ_TYPE = ["객관식", "초성 퀴즈", "OX 퀴즈"];

const QuizEditPage = () => {
  const navigate = useNavigate();

  const [goBackModalOpened, setGoBackModalOpened] = useState(false);

  const preventGoBack = () => {
    setGoBackModalOpened(true);
    window.history.pushState(null, "", window.location.href);
  };

  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ""; //Chrome에서 동작하도록; deprecated
  };

  const handleGoBackModal = () => {
    setGoBackModalOpened(!goBackModalOpened);
  };

  const goBack = () => {
    navigate("/features/quiz");
  };

  useEffect(() => {
    window.addEventListener("popstate", preventGoBack);
    window.addEventListener("beforeunload", preventClose);

    return () => {
      window.removeEventListener("popstate", preventGoBack);
      window.removeEventListener("beforeunload", preventClose);
    };
  }, []);

  return (
    <QuizProvider>
      <SnackBarProvider>
        <QuizEdit />
      </SnackBarProvider>

      <GoBackModal open={goBackModalOpened} onClose={handleGoBackModal} goBack={goBack} />
    </QuizProvider>
  );
};

const QuizEdit = () => {
  const { title, slideList, setTitle, setSlideList, setCurrentSlide } = useContext(QuizContext);
  const navigate = useNavigate();
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const SaveQuiz = async () => {
    const isTitleBlank = hasBlankTitle();
    const slideNotCompleted = hasNotCompletedSlide();

    if (isTitleBlank || slideNotCompleted) {
      if (isTitleBlank) setText("해당 라이브퀴즈의 제목을 입력해주세요.");
      else if (slideNotCompleted) setText("모든 슬라이드 편집을 완료 후 저장해주세요.");

      setSeverity("error");
      setOpen(true);
      return;
    }

    const uploadImage = () => {
      return new Promise(async (resolve, reject) => {
        let newSlideList = [];

        for (let slide of slideList) {
          if (slide.type === "객관식") {
            let newAnswers = [];

            for (let answer of slide.answers) {
              if (answer.mediaObject) {
                let fileName = `${answer.id}(original).${answer.mediaName.split(".")[1]}`;
                let croppedFileName = `${answer.id}(cropped).${
                  answer.mediaCroppedObject.type.split("/")[1]
                }`;

                await API.uploadImage(answer.mediaObject, fileName, (res) => {});

                await API.uploadImage(answer.mediaCroppedObject, croppedFileName, (res) => {});

                newAnswers.push({
                  ...answer,
                  mediaObject: null,
                  mediaCroppedObject: null,
                  mediaObjectUrl: null,
                  mediaCroppedObjectUrl: null,
                });
              } else {
                newAnswers.push(answer);
              }
            }

            newSlideList.push({
              ...slide,
              mediaObject: null,
              mediaCroppedObject: null,
              mediaObjectUrl: null,
              mediaCroppedObjectUrl: null,
              answers: newAnswers,
            });
          } else {
            newSlideList.push({
              ...slide,
              mediaObject: null,
              mediaCroppedObject: null,
              mediaObjectUrl: null,
              mediaCroppedObjectUrl: null,
            });
          }

          if (slide.mediaObject) {
            const fileName = `${slide.id}(original).${slide.mediaObject.type.split("/")[1]}`;
            const croppedFileName = `${slide.id}(cropped).${
              slide.mediaCroppedObject.type.split("/")[1]
            }`;

            await API.uploadImage(slide.mediaObject, fileName, (res) => {});
            await API.uploadImage(slide.mediaCroppedObject, croppedFileName, (res) => {});
          }
        }

        resolve(newSlideList);
      });
    };

    // let newSlideList = [];

    // for (let slide of slideList) {
    //   if (slide.type === "객관식") {
    //     let newAnswers = [];

    //     for (let answer of slide.answers) {
    //       if (answer.mediaObject) {
    //         let fileName = `${answer.id}(original).${answer.mediaName.split(".")[1]}`;
    //         let croppedFileName = `${answer.id}(cropped).${
    //           answer.mediaCroppedObject.type.split("/")[1]
    //         }`;

    //         await API.uploadImage(answer.mediaObject, fileName, (res) => {});

    //         await API.uploadImage(answer.mediaCroppedObject, croppedFileName, (res) => {});

    //         newAnswers.push({
    //           ...answer,
    //           mediaObject: null,
    //           mediaCroppedObject: null,
    //           mediaObjectUrl: null,
    //           mediaCroppedObjectUrl: null,
    //         });
    //       } else {
    //         newAnswers.push(answer);
    //       }
    //     }

    //     newSlideList.push({
    //       ...slide,
    //       mediaObject: null,
    //       mediaCroppedObject: null,
    //       mediaObjectUrl: null,
    //       mediaCroppedObjectUrl: null,
    //       answers: newAnswers,
    //     });
    //   } else {
    //     newSlideList.push({
    //       ...slide,
    //       mediaObject: null,
    //       mediaCroppedObject: null,
    //       mediaObjectUrl: null,
    //       mediaCroppedObjectUrl: null,
    //     });
    //   }

    //   if (slide.mediaObject) {
    //     const fileName = `${slide.id}(original).${slide.mediaObject.type.split("/")[1]}`;
    //     const croppedFileName = `${slide.id}(cropped).${
    //       slide.mediaCroppedObject.type.split("/")[1]
    //     }`;

    //     await API.uploadImage(slide.mediaObject, fileName, (res) => {});
    //     await API.uploadImage(slide.mediaCroppedObject, croppedFileName, (res) => {});
    //   }
    // }

    // console.log("end");

    setLoading({ text: "퀴즈를 저장하는 중입니다." });

    uploadImage().then((res) => {
      let newSlideList = res;

      POST_quizData(id, title, newSlideList, (res) => {
        let data = res.data;

        if (data.success) {
          setText("저장되었습니다.");
          setSeverity("success");
          setOpen(true);

          setTimeout(() => {
            navigate("/features/quiz");
            // window.close();
          }, 700);
        }
      });
    });

    // POST_quizData(id, title, newSlideList, (res) => {
    //   let data = res.data;

    //   if (data.success) {
    //     setText("저장되었습니다.");
    //     setSeverity("success");
    //     setOpen(true);

    //     setTimeout(() => {
    //       navigate("/features/quiz");
    //       // window.close();
    //     }, 700);
    //   }
    // });
  };

  const hasBlankTitle = () => {
    return title === "" || containsOnlySpaces();
  };

  const containsOnlySpaces = () => {
    const trimmed = title.replace(/\s/g, "");

    return trimmed.length === 0;
  };

  const hasNotCompletedSlide = () => {
    const notCompletedSlide = slideList.filter((item) => !item.isCompleted);

    return notCompletedSlide.length !== 0;
  };

  const getType = (type) => {
    switch (type) {
      case "png":
      case "PNG":
        type = "png";
        break;

      case "jpg":
      case "jpeg":
      case "JPG":
      case "JPEG":
        type = "jpeg";
        break;

      default:
        break;
    }

    return type;
  };

  const getImages = (slideList) => {
    // return new Promise(async (resolve, reject) => {
    //   API.getImage("quiz-media", (res) => {
    //     console.log(res);
    //   });
    //   resolve(slideList);
    // });
    return new Promise(async (resolve, reject) => {
      for (let slide of slideList) {
        if (slide.mediaName) {
          let list = slide.mediaName.split(".");
          let type = list[list.length - 1];
          // let type = slide.mediaName.split(".")[1];

          type = getType(type);

          // const originalFile = await AWS.downloadFile(
          //   `quiz-media/${slide.id}(original).${type}`,
          //   (res) => {
          //     let url = `data:image/${type};base64,${res.Body.toString("base64")}`;
          //     slide.mediaObject = url;
          //   }
          // );

          const originalFile = await fetch(
            `https://quiz.withplus.live/image?slide-id=${slide.id}&type=original&game-id=${id}`
          )
            .then((res) => {
              return res.blob();
            })
            .then((blob) => {
              return blob;

              let url = URL.createObjectURL(blob);

              return url;
            });

          // .then((res) => {

          // })
          // .then((blob) => {
          //   let url = URL.createObjectURL(blob);

          //   return url;
          // });

          // const croppedFile = await AWS.downloadFile(
          //   `quiz-media/${slide.id}(cropped).png`,
          //   (res) => {
          //     let url = `data:image/png;base64,${res.Body.toString("base64")}`;
          //     slide.mediaCroppedObject = url;
          //   }
          // );

          const croppedFile = await fetch(
            `https://quiz.withplus.live/image?slide-id=${slide.id}&type=cropped&game-id=${id}`
          )
            .then((res) => {
              return res.blob();
            })
            .then((blob) => {
              return blob;
              let url = URL.createObjectURL(blob);
              // slide.mediaCroppedObject = url;

              return url;
            });

          // let originalFileUrl = `data:image/${type};base64,${originalFile.Body.toString("base64")}`;
          // let originalFileUrl = originalFile;
          let originalFileUrl = URL.createObjectURL(originalFile);

          // let croppedFileUrl = `data:image/png;base64,${croppedFile.Body.toString("base64")}`;
          // let croppedFileUrl = croppedFile;
          let croppedFileUrl = URL.createObjectURL(croppedFile);

          // slide.mediaObject = originalFileUrl;
          slide.mediaObject = originalFile;
          slide.mediaObjectUrl = originalFileUrl;
          // slide.mediaCroppedObject = croppedFileUrl;
          slide.mediaCroppedObject = croppedFile;
          slide.mediaCroppedObjectUrl = croppedFileUrl;
        }

        if (slide.type === "객관식") {
          for (let answer of slide.answers) {
            if (answer.mediaName) {
              let type = answer.mediaName.split(".")[1];

              type = getType(type);

              // const originalFile = await AWS.downloadFile(
              //   `quiz-media/${answer.id}(original).${type}`,
              //   (res) => {}
              // );
              const originalFile = await fetch(
                `https://quiz.withplus.live/image?slide-id=${slide.id}&type=original&answer-id=${answer.id}&game-id=${id}`
              )
                .then((res) => {
                  return res.blob();
                })
                .then((blob) => {
                  return blob;
                  // let url = URL.createObjectURL(blob);
                  // return url;
                });

              // const croppedFile = await AWS.downloadFile(
              //   `quiz-media/${answer.id}(cropped).png`,
              //   (res) => {}
              // );

              const croppedFile = await fetch(
                `https://quiz.withplus.live/image?slide-id=${slide.id}&type=cropped&answer-id=${answer.id}&game-id=${id}`
              )
                .then((res) => {
                  return res.blob();
                })
                .then((blob) => {
                  return blob;
                  // let url = URL.createObjectURL(blob);
                  // return url;
                });

              // let originalFileUrl = `data:image/${type};base64,${originalFile.Body.toString(
              //   "base64"
              // )}`;
              // let croppedFileUrl = `data:image/png;base64,${croppedFile.Body.toString("base64")}`;
              let originalFileUrl = URL.createObjectURL(originalFile);
              let croppedFileUrl = URL.createObjectURL(croppedFile);

              answer.mediaObject = originalFile;
              answer.mediaObjectUrl = originalFileUrl;
              answer.mediaCroppedObject = croppedFile;
              answer.mediaCroppedObjectUrl = croppedFileUrl;
            }
          }
        }
      }

      resolve(slideList);
    });
  };

  useEffect(() => {
    GET_QuizData(id, async (res) => {
      let { quiz } = res.data;

      if (!quiz) return;

      setTitle(quiz.name);

      if (quiz.slideList.length !== 0) {
        setLoading({ text: "이미지를 불러오는 중입니다." });
        getImages(quiz.slideList).then((res) => {
          setSlideList(res);
          setCurrentSlide(res[0]);
          setLoading(null);
        });
      }
    });
  }, []);

  return (
    <>
      <Layout action={SaveQuiz}>
        {{
          name: "Live Quiz",

          content: (
            <Grid container sx={{ p: "5px", boxSizing: "border-box", height: "100%" }}>
              {/* // * 슬라이드 Contaienr */}
              <SlideListContainer />

              {/* // * 미리보기 Container */}
              <SlideContainer />

              {/* // * 수정 Container */}
              <EditContainer />
            </Grid>
          ),
        }}
      </Layout>

      {/* <Tooltip
        arrow
        title={<Typography sx={classes.save_button_tooltip_title}>저장하기</Typography>}
      >
        <IconButton sx={classes.save_button} onClick={SaveQuiz}>
          <SaveIcon fontSize="large" sx={classes.save_button_icon} />
        </IconButton>
      </Tooltip> */}

      {/* <LoadingModal open={loading} /> */}
      {loading && <FileLoadingModal loading={loading} text={loading.text} />}

      {/* <SpeedDial
        open={true}
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", top: 90, right: 20 }}
        icon={<MoreHorizIcon />}
        direction="down"
      >
        <SpeedDialAction
          sx={{ width: "40px", height: "40px" }}
          icon={<SaveIcon sx={{ color: "white" }} />}
          tooltipTitle={<Typography children="저장하기" sx={{ fontFamily: "NotoSansKR-Medium" }} />}
          //   tooltipOpen
          TooltipClasses={{
            "&.staticTooltipLabel": { backgroundColor: "red" },
          }}
          FabProps={{
            style: {
              backgroundColor: "#c33c3c",
            },
          }}
        />

        <SpeedDialAction
          sx={{ width: "40px", height: "40px" }}
          icon={<UploadFileRoundedIcon sx={{ color: "white" }} />}
          tooltipTitle={<Typography children="불러오기" sx={{ fontFamily: "NotoSansKR-Medium" }} />}
          //   tooltipOpen
          FabProps={{
            style: {
              backgroundColor: "#c33c3c",
            },
          }}
        />
      </SpeedDial> */}
    </>
  );
};

const SlideListContainer = () => {
  const { slideList, setSlideList, setCurrentSlide } = useContext(QuizContext);
  // ? 구독 모델에 따라 슬라이드 개수 제한할 예정
  // const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const onDragEnd = (res) => {
    const sourceIndex = res.source.index;
    const destinationIndex = res.destination.index;

    let source = slideList.filter((_, index) => index === sourceIndex);
    let remains = slideList.filter((_, index) => index !== sourceIndex);

    let newSlideList = [
      ...remains.slice(0, destinationIndex),
      ...source,
      ...remains.slice(destinationIndex),
    ];

    setSlideList(newSlideList);
  };

  const addSlide = () => {
    // ? 구독 모델에 따라 슬라이드 개수 제한할 예정
    // if (slideList.length === 3) {
    //   setText("슬라이드")
    //   return;
    // }

    let newSlide = QuizTemplate();

    setSlideList([...slideList, newSlide]);
    setCurrentSlide(newSlide);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item xs={1.8} sx={{ ...classes.slideList.grid }}>
        <Box sx={classes.slideList.container}>
          <Typography sx={{ font: "600 18px Pretendard" }}>
            총 문제 : {slideList.length} 문제
          </Typography>
        </Box>

        <Droppable droppableId="slideList">
          {(provided) => (
            <Box
              sx={classes.slideList.content_box}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {slideList.map((item, index) => (
                <Draggable draggableId={item.id} index={index} key={item.id}>
                  {(provided) => (
                    <div
                      key={item.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ListItem key={item.id} index={index} data={item} />
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Box sx={{ ...classes.slideList.add_slide_box }}>
          <Button fullWidth onClick={addSlide}>
            <AddCircleOutlineIcon fontSize="large" />
            <Typography sx={{ font: "700 18px Pretendard" }}>&nbsp;슬라이드 추가</Typography>
          </Button>
        </Box>
      </Grid>
    </DragDropContext>
  );
};

const SlideContainer = () => {
  const { title, currentSlide, handleTitle, slideList } = useContext(QuizContext);
  return (
    <Grid item xs={7.4} sx={classes.slide.grid}>
      <Box sx={classes.slide.container}>
        <TextField
          sx={{ ...classes.slide.title_textfield, border: "2px solid #c33c3c" }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                children={<p style={{ color: "#c33c3c" }}>제목* : </p>}
              />
            ),
          }}
          placeholder="제목을 입력해주세요."
          value={title}
          onChange={handleTitle}
        />
      </Box>

      <Box sx={classes.slide.slide_box}>
        <Paper sx={classes.slide.slide_paper}>
          <Grid container sx={classes.slide.paper_container}>
            <Grid item xs={12} sx={classes.slide.grid_left}>
              <Box sx={classes.slide.content_box}>
                <Box sx={classes.slide.content_header}>
                  <Box sx={{ display: "flex", gap: "5px", alignItems: "center", opacity: "0.75" }}>
                    <img src={logo} alt="logo" style={{ width: "20px", height: "20px" }} />

                    <Typography sx={classes.slide.content_header_title}>
                      Withplus Live - 라이브 퀴즈
                    </Typography>
                  </Box>

                  <Box sx={classes.slide.content_header_participants}>
                    <Typography sx={{ fontWeight: "600" }}>3 / 10</Typography>
                    <PeopleIcon sx={{ ml: 1 }} />
                  </Box>
                </Box>

                <Box sx={classes.slide.timeline_container}>
                  <Typography sx={{ fontWeight: "700", pr: "10px" }}>
                    {slideList.findIndex((item) => item.id === currentSlide.id) + 1} /{" "}
                    {slideList.length}
                  </Typography>

                  <Box sx={classes.slide.timeline_box}>
                    <Box sx={classes.slide.timeline_full_width}>
                      <Box sx={classes.slide.timeline_remains} />
                    </Box>
                  </Box>

                  <Box sx={classes.slide.timeline_text_box}>
                    <AlarmIcon sx={classes.slide.alarm_icon} />
                    <Typography sx={{ fontWeight: "600" }}>
                      {currentSlide.timer + "" === "0" ? "제한없음" : `${currentSlide.timer}초`}
                    </Typography>
                  </Box>
                </Box>

                <QuizSlide />
              </Box>
            </Grid>

            {/* <Grid item xs={3} sx={classes.slide.slide_right}>
              <Box sx={classes.slide.code_box}>
                <Box sx={classes.slide.qrcode_box}>
                  <QrCode2Icon sx={classes.slide.qrcode} />
                </Box>

                <Box>
                  <Typography sx={classes.slide.access_code_text}>접속 코드</Typography>
                  <Typography sx={classes.slide.access_code}>123 456</Typography>
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};

const EditContainer = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);

  const [imageName, setImageName] = useState("");
  const [questionImageModalOpened, setQuestionImageModalOpened] = useState(false);
  // const [croppedData, setCroppedData] = useState(null);
  // const [uploadedImage, setUploadedImage] = useState(null);
  // const [croppedImage, setCroppedImage] = useState(null);

  const handleQuestion = (e) => {
    let question = e.target.value.slice(0, 150);

    updateCurrentSlide({ question });
  };

  const handleMediaType = (e) => {
    if (e.target.value === "") {
      if (
        window.confirm(
          " 미디어를 사용하지 않겠습니까? \n 이미 업로드한 미디어는 삭제됩니다. \n (미디어를 다시 사용하려면 이미지를 다시 업로드해주세요.)"
        )
      ) {
        updateCurrentSlide({
          mediaType: e.target.value,
          mediaObject: null,
          mediaName: null,
          mediaCroppedObject: null,
          croppedData: null,
        });
      }
    } else {
      updateCurrentSlide({ mediaType: e.target.value });
    }
  };

  const handleQuestionImageModal = () => {
    setQuestionImageModalOpened((prev) => !prev);
  };

  useEffect(() => {
    // console.log(currentSlide);

    setImageName(currentSlide.mediaName ? currentSlide.mediaName : "");
  }, [currentSlide]);

  return (
    <Grid item xs={2.8} sx={classes.edit.grid}>
      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>문제 유형</Typography>

        <Grid container>
          {QUIZ_TYPE.map((item) => (
            <QuizType item={item} key={item} selected={currentSlide.type === item} />
          ))}
        </Grid>
      </Box>
      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>
          문제 (Question) <span>(150자 이내)</span>
        </Typography>

        <TextField
          multiline
          fullWidth
          placeholder="문제를 입력해주세요."
          value={currentSlide.question}
          onChange={handleQuestion}
          InputProps={{
            endAdornment: (
              <span style={{ font: "500 12px Pretendard", color: "#777" }}>
                {currentSlide.question.length}/150
              </span>
            ),
          }}
        />
      </Box>

      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>미디어 (Media)</Typography>

        <FormControl sx={{ ml: "10px" }}>
          <RadioGroup row value={currentSlide.mediaType || ""} onChange={handleMediaType}>
            <FormControlLabel value="image" control={<Radio size="small" />} label="이미지" />
            <FormControlLabel value="" control={<Radio size="small" />} label="사용안함" />
          </RadioGroup>
        </FormControl>

        <Box
          sx={{
            display: currentSlide.mediaType === "image" ? "flex" : "none",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <TextField fullWidth size="small" disabled value={imageName} />

          <Tooltip title="이미지 업로드" arrow>
            <IconButton
              sx={{
                width: "40px",
                height: "40px",
                bgcolor: "#f5f5f5",
                boxShadow: "2px 2px 6px 0px rgba(0,0,0,0.5)",
                "&:hover": { bgcolor: "#ddd" },
                "&:active": { boxShadow: "none", transform: "translateY(2px)" },
              }}
              // onClick={openImageInput}
              onClick={handleQuestionImageModal}
            >
              <ImageIcon />
            </IconButton>
          </Tooltip>

          {questionImageModalOpened && (
            <UploadImageModal
              open={questionImageModalOpened}
              handleModal={handleQuestionImageModal}
              type="question"
            />
          )}
        </Box>
      </Box>

      <QuizEditor />
    </Grid>
  );
};

const ListItem = (props) => {
  const { data, index } = props;
  const { slideList, currentSlide, setSlideList, setCurrentSlide } = useContext(QuizContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const handleCurrentSlide = (e) => {
    e.preventDefault();
    setCurrentSlide(slideList.find((item) => item.id === e.currentTarget.id));
  };

  const deleteSlide = (e) => {
    e.stopPropagation();

    if (slideList.length === 1) {
      setText("최소 1개 이상의 슬라이드가 있어야 합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    if (currentSlide.id === e.currentTarget.id) {
      const newSlideList = slideList.filter((item) => item.id !== e.currentTarget.id);

      let index = slideList.findIndex((item) => item.id === e.currentTarget.id);

      if (index >= newSlideList.length) {
        index = newSlideList.length - 1;
      }

      setSlideList(newSlideList);
      setCurrentSlide(newSlideList[index]);
    } else {
      setSlideList(slideList.filter((item) => item.id !== e.currentTarget.id));
    }
  };

  const duplicateSlide = (e) => {
    e.stopPropagation();
    let newSlideList = [];

    for (let slide of slideList) {
      newSlideList.push(slide);

      if (slide.id === e.currentTarget.id) {
        let newSlide = { ...slide, id: uuidv4() };

        if (slide.type === "객관식") {
          let newAnswers = [];
          let newCorrectAnswerIDs = [];

          for (let answer of slide.answers) {
            let newAnswer = { ...answer, id: uuidv4() };

            if (slide.correctAnswerIDs.includes(answer.id)) {
              newCorrectAnswerIDs.push(newAnswer.id);
            }

            if (answer.mediaObject) {
              let newMediaObject = new Blob([answer.mediaObject], {
                type: answer.mediaObject.type,
              });
              let newMediaCroppedObject = new Blob([answer.mediaCroppedObject], {
                type: answer.mediaCroppedObject.type,
              });

              let newMediaObjectUrl = URL.createObjectURL(newMediaObject);
              let newMediaCroppedObjectUrl = URL.createObjectURL(newMediaCroppedObject);

              newAnswer = {
                ...newAnswer,
                mediaObject: newMediaObject,
                mediaCroppedObject: newMediaCroppedObject,
                mediaObjectUrl: newMediaObjectUrl,
                mediaCroppedObjectUrl: newMediaCroppedObjectUrl,
              };
            }

            newAnswers.push(newAnswer);
          }

          newSlide = { ...newSlide, answers: newAnswers, correctAnswerIDs: newCorrectAnswerIDs };
        }

        if (slide.mediaObject) {
          let newMediaObject = new Blob([slide.mediaObject], {
            type: slide.mediaObject.type,
          });
          let newMediaCroppedObject = new Blob([slide.mediaCroppedObject], {
            type: slide.mediaCroppedObject.type,
          });

          let newMediaObjectUrl = URL.createObjectURL(newMediaObject);
          let newMediaCroppedObjectUrl = URL.createObjectURL(newMediaCroppedObject);

          newSlide = {
            ...newSlide,
            mediaObject: newMediaObject,
            mediaCroppedObject: newMediaCroppedObject,
            mediaObjectUrl: newMediaObjectUrl,
            mediaCroppedObjectUrl: newMediaCroppedObjectUrl,
          };
        }

        newSlideList.push(newSlide);
        // else {
        //   if (slide.mediaObject) {
        //     let newMediaObject = new Blob([slide.mediaObject], {
        //       type: slide.mediaObject.type,
        //     });
        //     let newMediaCroppedObject = new Blob([slide.mediaCroppedObject], {
        //       type: slide.mediaCroppedObject.type,
        //     });

        //     let newMediaObjectUrl = URL.createObjectURL(newMediaObject);
        //     let newMediaCroppedObjectUrl = URL.createObjectURL(newMediaCroppedObject);

        //     newSlide = {
        //       ...newSlide,
        //       mediaObject: newMediaObject,
        //       mediaCroppedObject: newMediaCroppedObject,
        //       mediaObjectUrl: newMediaObjectUrl,
        //       mediaCroppedObjectUrl: newMediaCroppedObjectUrl,
        //     };
        //   }
        // }

        // newSlideList.push(newSlide);
      }
    }

    setSlideList(newSlideList);
  };

  return (
    <>
      <Box sx={classes.list_item.box}>
        <Typography fontWeight={600}>{index + 1}.</Typography>

        <Paper
          id={data.id}
          sx={{
            ...classes.list_item.paper,
            border: data.id === currentSlide.id ? 2 : "",
            borderColor: data.id === currentSlide.id ? "#c33c3c" : "",
          }}
          onClick={handleCurrentSlide}
        >
          <Box sx={classes.list_item.has_complete_box}>
            {data.isCompleted ? (
              <CheckCircleIcon sx={{ color: "green" }} />
            ) : (
              <ErrorIcon sx={{ color: "red" }} />
            )}
          </Box>

          <Box sx={classes.list_item.slide_type_box}>
            <Typography fontWeight={700} fontSize="18px">
              [{data.type}]{" "}
            </Typography>
          </Box>

          <Box>
            <Typography noWrap> {data.question || "No Question"}</Typography>
          </Box>

          <Box sx={classes.list_item.icon_buttons_box}>
            <Tooltip title="복제" arrow>
              <IconButton id={data.id} sx={classes.list_item.icon_button} onClick={duplicateSlide}>
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="삭제" arrow>
              <IconButton sx={classes.list_item.icon_button} id={data.id} onClick={deleteSlide}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

const FileLoadingModal = (props) => {
  return (
    <Modal open={true} disableAutoFocus disableEnforceFocus>
      <Box
        sx={{
          bgcolor: "#ffffff10",
          height: "100%",
          WebkitBackdropFilter: "blur(3px)",
          backdropFilter: "blur(3px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: "10px",
            padding: "30px 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Typography sx={{ font: "700 20px Pretendard", textAlign: "center" }}>
            {props.text} <br />
            잠시만 기다려주세요.
          </Typography>

          <CircularProgress size={60} />
        </Box>
      </Box>
    </Modal>
  );
};

export default QuizEditPage;
