import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Box, Button, Divider, MenuItem, Select, Typography, Badge } from "@mui/material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DownloadIcon from "@mui/icons-material/Download";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

import { DELETE_QuizResult, GET_Auth, GET_QuizResult, POST_DownloadReport } from "utils/api/Quiz";
import { LoadingButton } from "@mui/lab";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

export default () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [cookies] = useCookies();

  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [quiz, setQuiz] = useState(null);
  const [history, setHistory] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState("-1");
  const [width, setWidth] = useState("0%");
  const [playingUsers, setPlayingUsers] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "score", order: "desc" });
  const [sortedPlayingUsers, setSortedPlayingUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    navigate("/features/quiz");
  };

  const handleSelect = (e) => {
    setSelectedIndex(e.target.value + "");
  };

  const handleSortBy = (e) => {
    // console.log(e.target.id);
    // let id = e.target.id;
    let id = e.currentTarget.id;

    if (sortBy.key === id) {
      if (sortBy.order === "desc") {
        setSortBy({ key: id, order: "asc" });
      } else {
        setSortBy({ key: id, order: "desc" });
      }
    } else {
      setSortBy({ key: id, order: "desc" });
    }
  };

  const deleteReport = () => {
    const confirm = window.confirm(
      ` ${selectedHistory.round}회차의 결과 리포트를 삭제하시겠습니까? \n 삭제된 결과 리포트는 복구할 수 없습니다.`
    );

    if (confirm) {
      DELETE_QuizResult(id, selectedHistory.round, (response) => {
        const { success } = response.data;

        if (success) {
          alert("삭제되었습니다.");
        } else {
          alert("삭제에 실패했습니다.");
        }

        window.location.reload();
      });
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const downloadReport = async () => {
    setLoading(true);

    const authResponse = await GET_Auth(cookies.auth);

    await sleep(500);

    if (!authResponse.data.success) {
      alert("로그인이 만료되었습니다. 다시 로그인해주세요.");

      window.location.reload();

      return;
    } else {
      let { user } = authResponse.data;

      if (user.plan === "free") {
        setLoading(false);

        setOpen(true);
        setSeverity("warning");
        setText("베이직 플랜 이상의 유저만 다운로드가 가능합니다.");
        return;
      }
    }

    const slideList = selectedHistory.slideList;

    const json = selectedHistory.playingUsers.map((item) => {
      const historyToObj = item.history.reduce((acc, cur, index) => {
        acc[`${index + 1}번\n${slideList[index].score}점 / ${slideList[index].timer}초`] = `${
          cur.score
        }점\n${cur.isCorrect ? "O" : "X"}\n${cur.time ? `${cur.time}초` : "미제출"}`;

        return acc;
      }, {});

      return {
        이름: item.name,
        총점: item.score,
        ...historyToObj,
      };
    });

    POST_DownloadReport(
      cookies.auth,
      `${quiz.name} ${selectedHistory.round}회차 (${selectedHistory.endedTime.slice(0, 10)}).csv`,
      json,
      (response) => {
        console.log(response.data);
        const { success, url } = response.data;

        if (success) {
          window.open(url);
          setLoading(false);
        } else {
          alert("다운로드에 실패했습니다. 다시 시도해주세요.");

          window.location.reload();
        }
      }
    );
  };

  useEffect(() => {
    GET_QuizResult(id, (response) => {
      //   console.log(response.data);
      const { quiz } = response.data;

      setHistory(quiz.history);
      setQuiz(quiz);
      //   setSelectedIndex("0");
      //   setSeledtedHistory(quiz.history[quiz.history.length - 1]);
    });
  }, []);

  //   useEffect(() => {
  //     console.log(history);
  //   }, [history]);

  useEffect(() => {
    if (history.length > 0) {
      setSelectedHistory(history[selectedIndex]);
    }
  }, [selectedIndex, history]);

  useEffect(() => {
    if (selectedHistory) {
      const slideList = selectedHistory.slideList;

      setWidth(100 / (slideList.length + 2) + "%");
      setPlayingUsers(selectedHistory.playingUsers);
    }
  }, [selectedHistory]);

  useEffect(() => {
    if (sortBy.key === "name" || sortBy.key === "score") {
      const sorted = [...playingUsers].sort((a, b) => {
        if (sortBy.order === "asc") {
          return a[sortBy.key] - b[sortBy.key];
        } else {
          return b[sortBy.key] - a[sortBy.key];
        }
      });

      setSortedPlayingUsers(sorted);
    } else {
      const sorted = [...playingUsers].sort((a, b) => {
        const aHistory = a.history[parseInt(sortBy.key.split("-")[1])]?.score || 0;
        const bHistory = b.history[parseInt(sortBy.key.split("-")[1])]?.score || 0;

        if (sortBy.order === "asc") {
          return aHistory - bHistory;
        } else {
          return bHistory - aHistory;
        }
      });

      setSortedPlayingUsers(sorted);
    }
  }, [sortBy, playingUsers]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        pt: "80px",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Button
          sx={{ display: "flex", gap: "5px", alignItems: "center", mt: "10px", ml: "10px" }}
          onClick={goBack}
        >
          <KeyboardBackspaceIcon />
          <Typography sx={{ fontWeight: "600" }}>뒤로가기</Typography>
        </Button>
      </Box>

      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            width: "calc(100% - 80px)",
            borderRadius: "6px",
            boxShadow: " 0 3px 6px #00000038",
            height: "calc(100% - 20px)",
            p: "10px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: "28px", fontWeight: "700" }}>퀴즈 결과 리포트</Typography>

          <Divider sx={{ my: "5px" }} />

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", alignItems: "center" }}
          >
            <Typography sx={{ font: "700 20px Pretendard" }}>회차 선택 :</Typography>

            <Select
              sx={{ bgcolor: "#f0f0f0", fontWeight: "600", color: "#555" }}
              value={selectedIndex}
              onChange={handleSelect}
            >
              <MenuItem
                value="-1"
                sx={{ font: "600 16px Pretendard", height: "0px", overflow: "hidden", p: 0 }}
                disabled
              >
                결과 리포트 회차를 선택해주세요
              </MenuItem>

              {history &&
                history.map((item, index) => (
                  <MenuItem key={index} value={index + ""} sx={{ font: "600 16px Pretendard" }}>
                    {`${quiz.name} ${item.round}회차 ${
                      item.endedTime ? `(${item.endedTime.slice(0, 10)})` : ""
                    }`}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          {selectedIndex !== "-1" && (
            <Typography sx={{ font: "600 16px Pretendard" }}>
              응시자 수 : {selectedHistory?.playingUsers.length}명
            </Typography>
          )}

          {selectedIndex !== "-1" && (
            <Box
              sx={{
                mt: "10px",
                py: "5px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Badge
                badgeContent={
                  <Typography
                    sx={{
                      font: "700 12px Pretendard",
                      "> span": { fontSize: "18px" },
                    }}
                  >
                    베이직 <span>+</span>
                  </Typography>
                }
                color="success"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <LoadingButton
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    color: "#2073A1",
                    border: "2px solid #2073a1",
                    bgcolor: "#2073A111",
                    "&:disabled": {
                      border: "2px solid #ddd",
                      bgcolor: "#ddd",
                    },
                  }}
                  onClick={downloadReport}
                  loading={loading}
                >
                  <DownloadIcon />
                  <Typography sx={{ fontWeight: "600" }}> CSV 다운로드</Typography>
                </LoadingButton>
              </Badge>

              <Button
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                onClick={deleteReport}
              >
                <DeleteIcon />
                <Typography sx={{ fontWeight: "600" }}>이 회차의 결과 리포트 삭제하기</Typography>
              </Button>
            </Box>
          )}

          <Box sx={{ mt: "10px", flex: 1, position: "relative" }}>
            <Box
              sx={{
                border: "2px solid #333",
                position: "absolute",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                overflow: "auto",
              }}
            >
              {selectedIndex === "-1" && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <AdsClickIcon sx={{ fontSize: "100px", color: "#88888888" }} />

                  <Typography sx={{ font: "700 24px Pretendard", ml: "10px", color: "#88888888" }}>
                    결과 리포트를 확인할 회차를 선택해주세요.
                  </Typography>
                </Box>
              )}

              {selectedIndex !== "-1" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      "> div": {
                        bgcolor: "#dfdfdf",
                        width,
                        minWidth: "150px",
                        minHeight: "50px",
                        flexShrink: 0,
                        borderBottom: "1px solid #88888888",
                        borderRight: "1px solid #88888888",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        px: "10px",
                        boxSizing: "border-box",
                        fontWeight: "600",
                        textAlign: "center",
                        cursor: "pointer",
                        userSelect: "none",
                        position: "relative",
                      },
                      "> div:last-child": {
                        borderRight: "none",
                      },
                      "> div:first-of-type": {
                        minWidth: "300px",
                        justifyContent: "flex-start",
                        cursor: "default",
                      },
                      "> div:nth-of-type(2)": {
                        justifyContent: "flex-start",
                      },
                    }}
                  >
                    {/* <Box onClick={handleSortBy} id="name"> */}
                    <Box>
                      이름
                      {/* <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: 0,
                          transform: "translateY(-50%)",
                        }}
                      >
                        {sortBy.key === "name" && sortBy.order === "asc" && <ArrowDropUpIcon />}
                        {sortBy.key === "name" && sortBy.order === "desc" && <ArrowDropDownIcon />}
                      </Box> */}
                    </Box>

                    <Box onClick={handleSortBy} id="score">
                      총점
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: 0,
                          transform: "translateY(-50%)",
                        }}
                      >
                        {sortBy.key === "score" && sortBy.order === "asc" && <ArrowDropUpIcon />}
                        {sortBy.key === "score" && sortBy.order === "desc" && <ArrowDropDownIcon />}
                      </Box>
                    </Box>

                    {selectedHistory &&
                      selectedHistory.slideList.map((item, index) => (
                        <Box key={index} id={"history-" + index} onClick={handleSortBy}>
                          {index + 1}번 <br />
                          {item.score}점 / {item.timer}초
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: 0,
                              transform: "translateY(-50%)",
                            }}
                          >
                            {sortBy.key === "history-" + index && sortBy.order === "asc" && (
                              <ArrowDropUpIcon />
                            )}
                            {sortBy.key === "history-" + index && sortBy.order === "desc" && (
                              <ArrowDropDownIcon />
                            )}
                          </Box>
                        </Box>
                      ))}
                  </Box>

                  {sortedPlayingUsers.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        "> div": {
                          bgcolor: index % 2 === 0 ? "#f5f5f5" : "#FFF",
                          width,
                          minWidth: "150px",
                          minHeight: "50px",
                          flexShrink: 0,
                          borderBottom: "1px solid #88888888",
                          borderRight: "1px solid #88888888",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          p: "10px",
                          boxSizing: "border-box",
                          fontWeight: "500",
                          textAlign: "center",
                          color: "#2073A1",
                        },
                        "> div:last-child": {
                          borderRight: "none",
                        },
                        "> div:first-of-type": {
                          minWidth: "300px",
                          justifyContent: "flex-start",
                        },
                        "> div:nth-of-type(2)": {
                          justifyContent: "flex-start",
                        },
                      }}
                    >
                      <Box>{item.name}</Box>

                      <Box>{item.score}</Box>

                      {item.history.map((history, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                        >
                          {history?.isCorrect ? (
                            <PanoramaFishEyeIcon sx={{ color: "blue" }} />
                          ) : (
                            <ClearIcon sx={{ color: "red" }} />
                          )}

                          <Typography sx={{ font: "600 16px Pretendard" }}>
                            {history?.score || 0}점
                          </Typography>

                          <Typography sx={{ font: "600 14px Pretendard" }}>
                            {history?.time ? `${history.time}초` : "미제출"}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
