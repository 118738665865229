// import cow from "./Buffalo-goal.png";
// import elephant from "./Elephant-goal.png";
// import tiger from "./Tiger-goal.png";
// import hippo from "./Hippo-goal.png";

import cow from "./Buffalo-goal.webp";
import elephant from "./Elephant-goal.webp";
import tiger from "./Tiger-goal.webp";
import hippo from "./Hippo-goal.webp";

export default [hippo, cow, elephant, tiger];
