import { useContext, useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";

import { Box, InputAdornment, TextField, Typography } from "@mui/material";
// import WavingHandIcon from "@mui/icons-material/WavingHand";

import * as S from "./styles";

import { CustomLoadingButton as Button } from "components/Button";
import { UserContext } from "../contexts/UserContext";

// import postItTiger from "assets/images/LiveTap/post-it-tiger.png";
import background from "assets/images/LiveTap_new/background_start.webp";

const UserInfo = (props) => {
  const { socket, currentLiveTap, name, setName } = useContext(UserContext);
  const [cookies] = useCookies();
  const [nameError, setNameError] = useState(false);

  // Create a ref for the TextField
  const textFieldRef = useRef(null);

  // Focus the TextField when the component mounts
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  const editName = (e) => {
    if (e.target.value.length > 5) return;
    setName(e.target.value);
  };

  const handleButtonClick = () => {
    setNameError(name === "");
    sessionStorage.setItem("lveiTapName", name);
    if (currentLiveTap?.gameMode === "team") {
      props.gotoTeamSelect();
    } else {
      socket.emit(
        "joinGame",
        { gameId: currentLiveTap._id, userName: name, userId: cookies.uid },
        (res) => {
          if (res.success) {
            props.gotoWaitingRoom();
          }
        }
      );
    }
  };

  return (
    <S.Intro>
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography
          sx={{
            font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
            mb: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentLiveTap?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
          padding: "10px",
        }}
      >
        <Typography sx={{ font: "700 clamp(15.6px, 5vw, 22px) Pretendard" }}>
          게임에서 사용할 닉네임을 입력해주세요.
        </Typography>

        <Typography sx={{ font: "600 clamp(14.2px, 4.5vw, 20px) Pretendard", my: "10px" }}>
          최대 5자
        </Typography>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "16/9",
            border: "3px solid #f8f8f8",
            borderRadius: "10px",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              bgcolor: "#ffffff50",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backdropFilter: "blur(5px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              // label="닉네임"
              variant="outlined"
              margin="normal"
              size="medium"
              placeholder="닉네임"
              value={name}
              onChange={editName}
              inputRef={textFieldRef} // Attach the ref here
              InputLabelProps={{
                sx: { font: "400 clamp(11.4px, 3.6vw, 16px) Pretendard" },
              }}
              InputProps={{
                sx: { font: "400 clamp(11.4px, 3.6vw, 16px) Pretendard" },
                endAdornment: (
                  <InputAdornment position="start">
                    <p style={{ font: "400 clamp(11.4px, 3.6vw, 16px) Pretendard" }}>
                      {5 - name.length}
                    </p>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "50%",
                backgroundColor: "white",
                border: "2px solid #f8f8f8",
                borderRadius: "8px",
                mt: "-10px",
                ml: "30px",
              }}
            />

            {nameError && (
              <Typography
                sx={{
                  font: "400 12px Pretendard",
                  color: "#d32f2f",
                  ml: "10px",
                  mb: "10px",
                  alignSelf: "flex-start",
                }}
              >
                닉네임을 입력해주세요.
              </Typography>
            )}
          </Box>
        </Box>

        {currentLiveTap?.gameMode === "team" ? (
          <Typography
            sx={{
              font: "600 clamp(14.2px, 4.5vw, 20px) Pretendard",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            아래의 팀 선택하기 버튼을 눌러
            <br />
            팀을 선택해주세요
          </Typography>
        ) : (
          <Typography
            sx={{
              font: "600 clamp(11.4px, 3.6vw, 16px) Pretendard",
              marginTop: "40px",
              textAlign: "center",
            }}
          >
            아래의 시작하기 버튼을 눌러
            <br />
            라이브 탭을 시작해주세요.
          </Typography>
        )}

        <Button
          onClick={handleButtonClick}
          size="large"
          disabled={name === ""} // Disable button if name is empty
          sx={{
            mt: "15px",
            padding: "10px clamp(14.2px, 4.5vw, 20px)",
            font: "700 clamp(14.2px, 4.5vw, 20px) Pretendard",
            bgcolor: name === "" ? "#e0e0e0" : "#d6e2fb", // Change color if disabled
            color: "#50586C",
            "&:hover": { bgcolor: name === "" ? "#e0e0e0" : "#c6d2eb" },
            boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          {currentLiveTap?.gameMode === "team" ? "팀 선택하기" : "라이브 탭 시작하기"}
        </Button>
      </Box>
    </S.Intro>
  );
};

export default UserInfo;
