// import cow from "./win-buffalo.png";
// import elephant from "./win-elephant.png";
// import tiger from "./win-tiger.png";
// import hippo from "./win-hippo.png";

import cow from "./win-buffalo.webp";
import elephant from "./win-elephant.webp";
import tiger from "./win-tiger.webp";
import hippo from "./win-hippo.webp";

export default [hippo, cow, elephant, tiger];
