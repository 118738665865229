import React, { useContext, useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";

import classes from "../styles/LiveQuizStyles";
import { QuizContext } from "../contexts/Quiz";
import { getImage } from "utils/aws";
import { divideHangul } from "utils/chosung";

const bgcolors = ["#F2EAA2", "#BFBCDE", "#F4CEC2", "#99CCCC", "#EDD39F", "#c26974"];

const QuizSlide = () => {
  const { currentSlide } = useContext(QuizContext);

  const [quizImageURL, setQuizImageURL] = useState("");

  useEffect(() => {
    if (currentSlide.mediaCroppedObject) {
      // if (quizImageURL) {
      //   URL.revokeObjectURL(quizImageURL);
      // }

      // const url = URL.createObjectURL(currentSlide.mediaCroppedObject);

      setQuizImageURL(currentSlide.mediaCroppedObjectUrl);
    }
  }, [currentSlide]);

  const SelectSlide = () => {
    const question_p = useRef(null);
    const question_box = useRef(null);

    const [answerImageURLs, setAnswerImageURLs] = useState({});

    const { currentSlide } = useContext(QuizContext);

    useEffect(() => {
      if (!question_p.current || !question_box.current || !currentSlide) return;

      const questionHeight = question_p.current.clientHeight;
      const questionBoxHeight = question_box.current.clientHeight;

      if (questionHeight > questionBoxHeight) {
        question_p.current.style.transform = `scale(${questionBoxHeight / questionHeight})`;
      } else {
        question_p.current.style.transform = `scale(1)`;
      }
    }, [currentSlide.question]);

    useEffect(() => {
      if (currentSlide.answers.length === 0) return;

      const urls = {};

      for (let answer of currentSlide.answers) {
        if (answer.mediaCroppedObject) {
          // const url = URL.createObjectURL(answer.mediaCroppedObject);

          urls[answer.id] = answer.mediaCroppedObjectUrl;
        }
      }

      setAnswerImageURLs(urls);
    }, [currentSlide]);

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container sx={classes.slide.question_box}>
          <Grid item xs={7} sx={{ position: "relative", height: "100%" }}>
            <Box
              ref={question_box}
              sx={{
                position: "absolute",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...classes.slide.question_text, height: "auto" }} ref={question_p}>
                {currentSlide.question || "문제를 입력해주세요."}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box
              sx={{
                aspectRatio: "16/9",
                border: "1px solid #eee",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {currentSlide.mediaObject && (
                // <img
                //   src={currentSlide.mediaCroppedObject}
                //   style={{ width: "100%", height: "100%", objectFit: "cover" }}
                //   alt="test"
                // />
                <img
                  src={quizImageURL}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="test"
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container rowGap="10px" sx={classes.slide.answers_grid}>
          {currentSlide.answers.map((item, idx) => (
            <Grid
              item
              xs={currentSlide.answers.length <= 4 ? 3 : 4}
              sx={classes.slide.answer_item}
              key={item.id}
            >
              <Box
                sx={{
                  ...classes.slide.answer_box,
                  backgroundColor: bgcolors[idx],
                  // backgroundImage: `url(${item.mediaCroppedObject})`,
                  backgroundImage: `url(${answerImageURLs[item.id]})`,
                  backgroundSize: "cover",
                  border: "2px solid",
                  borderColor:
                    currentSlide.correctAnswerIDs.indexOf(item.id) !== -1 ? "#00ff00" : "#aaa",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#eee",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: item.mediaCroppedObject ? 0.7 : 0,
                    zIndex: 1,
                  }}
                />

                <Typography
                  sx={{
                    color:
                      currentSlide.correctAnswerIDs.indexOf(item.id) !== -1 ? "#00ff00" : "#fff",
                    WebkitTextStroke: "0.5px #333",
                    textShadow: "3px 3px 2px #00000080",
                    font: "900 22px Pretendard",
                    fontSize: "clamp(13.125px, 0.9375vw, 18px)",
                    position: "absolute",
                    top: "2px",
                    right: "5px",
                    zIndex: 2,
                  }}
                >
                  {idx + 1}.
                </Typography>

                <Typography
                  sx={{
                    ...classes.slide.answer_text,
                    color:
                      currentSlide.correctAnswerIDs.indexOf(item.id) !== -1 ? "#00ff00" : "#fff",
                    WebkitTextStroke: "0.5px #333",
                    fontWeight: "700",
                    fontSize: "clamp(17.5px ,1.25vw, 24px)",
                    textShadow: "3px 3px 9px #00000050",
                    zIndex: 2,
                  }}
                >
                  {item.text || "답안을 입력해주세요."}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const ShortAnswerSlide = () => {
    const question_p = useRef(null);
    const question_box = useRef(null);

    const [chosungList, setChosungList] = useState(null);

    useEffect(() => {
      if (currentSlide.answer === "") return;

      const chos = [];
      const list = currentSlide.answer.split("");

      for (let i = 0; i < list.length; i++) {
        chos.push(divideHangul(list[i]));
      }

      setChosungList(chos);
    }, [currentSlide.answer]);

    useEffect(() => {
      if (!question_p.current || !question_box.current || !currentSlide) return;

      const questionHeight = question_p.current.clientHeight;
      const questionBoxHeight = question_box.current.clientHeight;

      if (questionHeight > questionBoxHeight) {
        question_p.current.style.transform = `scale(${questionBoxHeight / questionHeight})`;
      } else {
        question_p.current.style.transform = `scale(1)`;
      }
    }, [currentSlide.question]);

    return (
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Grid container sx={{ flex: 1 }}>
          <Grid item xs={7} sx={{ position: "relative" }}>
            <Box
              ref={question_box}
              sx={{
                ...classes.slide.short_answer_question_box,
                position: "absolute",
                border: 0,
                display: "flex",
                alignItems: "center",
                height: "100%",
                boxSizing: "border-box",
                overflow: "hidden",
              }}
            >
              <Typography
                ref={question_p}
                sx={{
                  ...classes.slide.question_text,
                  height: "auto",
                  textAlign: "left",
                  width: "100%",
                  // scale: "0.75",
                }}
              >
                {currentSlide.question || "문제를 입력해주세요."}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                aspectRatio: "16/9",
                border: "1px solid #eee",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {currentSlide.mediaObject && (
                // <img
                //   src={currentSlide.mediaCroppedObject}
                //   style={{ width: "100%", height: "100%", objectFit: "cover" }}
                //   alt="test"
                // />
                <img
                  src={quizImageURL}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="test"
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* <Box sx={classes.slide.short_answer_hint_container}>
          <Typography sx={classes.slide.hint_title}>힌트</Typography>

          <Box sx={classes.slide.hints_box}>
            {currentSlide.hints?.map((item, index) => (
              <Box key={index} sx={classes.slide.hint_button}>
                <Typography>{item.text || "힌트를 입력해주세요."}</Typography>
              </Box>
            ))}
          </Box>
        </Box> */}

        {chosungList && (
          <Box
            sx={{
              ...classes.slide.short_answer_container,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "20px",
              py: "25px",
            }}
          >
            {chosungList.map((item, idx) => (
              <Typography
                key={idx}
                sx={{
                  font: "900 32px Pretendard",
                  fontSize: "clamp(51px, 3.646vw, 70px)",
                  border: "2px solid #aaa",
                  color: "#333",
                  p: item === " " ? "0px" : "5px",
                  px: item === " " ? "0px" : "clamp(12.39px, 0.8854vw, 17px)",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  opacity: item === " " ? "0" : "1",
                  boxShadow:
                    "inset 0 -2px 0 #aaaaaa, inset 0px 1px 1px -1px #fff, 0px 1px 1px 0px #7a7a7a;",
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        )}

        {!chosungList && (
          <Box
            sx={{
              ...classes.slide.short_answer_container,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "20px",
              py: "25px",
            }}
          >
            <Typography sx={{ font: "900 32px Pretendard" }}>{"정답을 입력해주세요."}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const OXAnswerSlide = () => {
    const question_p = useRef(null);
    const question_box = useRef(null);

    useEffect(() => {
      if (!question_p.current || !question_box.current || !currentSlide) return;

      const questionHeight = question_p.current.clientHeight;
      const questionBoxHeight = question_box.current.clientHeight;

      if (questionHeight > questionBoxHeight) {
        question_p.current.style.transform = `scale(${questionBoxHeight / questionHeight})`;
      } else {
        question_p.current.style.transform = `scale(1)`;
      }
    }, [currentSlide.question]);

    return (
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container sx={classes.slide.OX_question_box}>
          <Grid item xs={7} sx={{ position: "relative", height: "100%" }}>
            <Box
              ref={question_box}
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Typography sx={{ ...classes.slide.question_text, height: "auto" }} ref={question_p}>
                {currentSlide.question || "문제를 입력해주세요."}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box
              sx={{
                aspectRatio: "16/9",
                border: "1px solid #eee",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {currentSlide.mediaObject && (
                // <img
                //   src={currentSlide.mediaCroppedObject}
                //   style={{ width: "100%", height: "100%", objectFit: "cover" }}
                //   alt="test"
                // />
                <img
                  src={quizImageURL}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt="test"
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={classes.slide.OX_answer_box}>
          <Grid item xs={6} sx={classes.slide.OX_answer_item}>
            <Box sx={classes.slide.OX_answer_button("O", currentSlide.answer)}>
              <PanoramaFishEyeIcon sx={{ fontSize: "10vw", color: "red" }} />
            </Box>
          </Grid>

          <Grid item xs={6} sx={classes.slide.OX_answer_item}>
            <Box sx={classes.slide.OX_answer_button("X", currentSlide.answer)}>
              <ClearIcon sx={{ fontSize: "10vw", color: "blue" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={classes.slide.quiz_box}>
      {(() => {
        switch (currentSlide.type) {
          case "객관식":
            return <SelectSlide />;
          case "초성 퀴즈":
            return <ShortAnswerSlide />;
          case "OX 퀴즈":
            return <OXAnswerSlide />;
          default:
            break;
        }
      })()}
    </Box>
  );
};

export default QuizSlide;
