import { Box, Checkbox, Modal, Typography } from "@mui/material";
import * as S from "./styles";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../contexts/UserContext";
// import { CustomButton as Button } from "../../../components/Button";
import TimerIcon from "@mui/icons-material/Timer";
import { useCookies } from "react-cookie";
// import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import characterMobile from "assets/images/LiveTap/characterMobile";

// import buttonImg from "assets/images/LiveTap/button.png";
// import memoMobile from "assets/images/LiveTap/memoMobile.png";
// import emphasisGif from "assets/images/LiveTap/characterMobile/emphasis.gif";
// import winnerAssets from "assets/images/LiveTap/winnerManager";
// import background from "assets/images/LiveTap_new/Bg-Mobile-Circle.png";
import background from "assets/images/LiveTap_new/Bg-Mobile-Circle.webp";
import Character from "assets/images/LiveTap_new/Character";
import focusEffect from "assets/images/LiveTap_new/focusEffectMobile.gif";
import { color } from "framer-motion";
import useInterval from "utils/useInterval";
// import tigerRun from "assets/images/LiveTap/tiger_run_tmp.gif";
// import tigerRun from "assets/images/LiveTap/tiger_run_tmp2.gif";

const GameScreen = () => {
  const tapRef = useRef();
  // const { state } = useLocation();
  // const { gameId } = useParams();
  // const navigate = useNavigate();

  const {
    currentLiveTap,
    socket,
    tapCount: tCount,
    // tapDisabled,
    team,
    // setTapDisabled,
    // setTapCount: setTapCountContext,
    showWinner,
    getTeamCharacter,
    teamScore,
    getData,
  } = useContext(UserContext);
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [countdown, setCountdown] = useState(3); // 카운트다운 상태 추가
  const [tapCount, setTapCount] = useState(0);
  const [cookies] = useCookies();
  const [gameEnded, setGameEnded] = useState(false); // 게임 종료 상태 추가
  const [timer, setTimer] = useState(0);
  const [restMode, setRestMode] = useState(false);
  const [winner, setWinner] = useState(null);
  const [tapPercent, setTapPercent] = useState(0);
  const [testMode, setTestMode] = useState(false);
  const [tempCount, setTempCount] = useState(0);
  // const [showEndingScreen, setShowEndingScreen] = useState(false);

  useEffect(() => {
    if (!currentLiveTap) return;

    if (currentLiveTap.isGameStart) {
      setOverlayVisible(false);
      setCountdown(0);
    }

    if (currentLiveTap.isGameEnded) {
      setGameEnded(true);
    }
  }, [currentLiveTap]);

  useEffect(() => {
    if (!showWinner || !currentLiveTap) return;

    if (currentLiveTap.gameMode === "team") {
      let teams = currentLiveTap?.teamData;

      // 팀별로 tapCount를 계산
      teams.forEach((team) => {
        team.totalTapCount =
          team.teamMembers.reduce((acc, user) => acc + user.tapCount, 0) /
          (team.teamMembers.length * 100);
      });

      let winner = teams.reduce((prev, current) =>
        prev.totalTapCount > current.totalTapCount ? prev : current
      );
      let winnerIdx = teams.findIndex((team) => team.id === winner.id);

      winner.character = getTeamCharacter(winnerIdx);

      setWinner(winner);
    }
  }, [showWinner]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer((prev) => prev + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    if (timer >= 1) {
      setRestMode(true);
    } else {
      setRestMode(false);
    }
    // console.log(timer);
  }, [timer]);

  useEffect(() => {
    if (countdown !== 0) return;
    // if (countdown === 0) {
    setOverlayVisible(false);
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    // }

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  useEffect(() => {
    if (!socket) return;

    socket.on("setTimer", (data) => {
      setCountdown(data.timer);
    });
    socket.on("gameEnded", () => {
      setGameEnded(true);
    });
    // socket.on("resetGame", () => {
    //   setTapCount(0);
    //   setGameEnded(false);
    //   setTapCountContext(0);
    //   navigate(`/tap/user/${gameId}/waitingRoom`);
    // });

    return () => {
      socket.off("setTimer");
      socket.off("gameEnded");
      // socket.off("resetGame");
    };
  });

  // TAP 버튼 클릭 시 호출되는 함수
  const handleTapClick = () => {
    setTempCount((prev) => prev + 1);
    setTimer(0);

    return;
    // if (!tapDisabled && tapCount < 100 && !gameEnded) {
    // 게임이 종료되지 않았을 때만 TAP 가능
    // setTapCount((prevCount) => prevCount + 1); // tapCount 증가
    if (currentLiveTap?._id) {
      // currentLiveTap이 null이 아닌지 확인
      socket.emit("userTap", {
        gameId: currentLiveTap._id,
        userId: cookies.uid,
        tapCount: tapCount + 1,
      });
    } else {
      console.log("currentLiveTap is null");
      getData();
    }
  };
  // };

  useEffect(() => {
    if (tCount > 0) {
      setTapCount(tCount);
    }
  }, [tCount]);

  useEffect(() => {
    // console.log("teamScore", teamScore);

    let tapPercent = (tapCount / (teamScore || 200)) * 100;
    // 소수점 첫번째 자리에서 반올림
    tapPercent = Math.round((tapPercent * 10) / 10);
    setTapPercent(tapPercent);
  }, [tapCount, teamScore]);

  // ? 테스트용 코드 (탭 자동화)
  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // useEffect(() => {
  //   let interval;
  //   const go = async () => {
  //     // const random = Math.floor(Math.random() * 1000);
  //     const random2 = Math.floor(Math.random() * 76) + 125;
  //     // await sleep(random);

  //     interval = setInterval(() => {
  //       tapRef.current?.click();
  //     }, random2);
  //   };
  //   if (!overlayVisible && testMode) {
  //     go();
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [overlayVisible, testMode]);

  useInterval(() => {
    console.log("tempCount", tempCount);
    if (gameEnded) return;
    if (overlayVisible) return;

    if (currentLiveTap?._id) {
      if (tempCount === 0) return;
      // currentLiveTap이 null이 아닌지 확인
      socket.emit("userTap", {
        gameId: currentLiveTap._id,
        userId: cookies.uid,
        tapCount: tempCount,
      });

      setTempCount(0);
    } else {
      console.log("currentLiveTap is null");
      getData();
    }
  }, 500);

  // GameScreen.jsx (User 화면)
  // useEffect(() => {
  //   socket.on("updatedTap", (updatedTap) => {
  //     setTapCount(
  //       updatedTap.individualData.find((user) => user.uid === cookies.uid)?.tapCount || 0
  //     );
  //     setGameEnded(updatedTap.isGamePaused);
  //     navigate(`/tap/user/${updatedTap._id}/waitingRoom`); // updatedTap._id를 사용하여 이동
  //   });

  //   return () => {
  //     socket.off("updatedTap");
  //   };
  // }, [socket, cookies.uid]);

  // if (!currentLiveTap) {
  //   return <div>Loading...</div>; // currentLiveTap이 null일 경우 로딩 표시
  // }

  // const Temp = () => {
  //   return (
  //     <Box
  //       sx={{
  //         boxSizing: "border-box",
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         width: "100%",
  //         padding: "10px",
  //         position: "relative",
  //         bgcolor: "#ff000050",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           width: "100%",
  //           font: "bold 22px pretendard",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           borderRadius: "10px",
  //           position: "relative",
  //           zIndex: 1,
  //           aspectRatio: "1573/1874",
  //           // bgcolor: "#ff000050",
  //         }}
  //       >
  //         <img
  //           src={memoMobile}
  //           style={{
  //             position: "absolute",
  //             width: "100%",
  //             filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25))",
  //           }}
  //         />

  //         {team?.character && (
  //           <img
  //             src={characterMobile[team.character].tape}
  //             style={{ position: "absolute", width: "90%", zIndex: 1, top: "-2.5%" }}
  //           />
  //         )}

  //         {team?.character && (
  //           <Box
  //             sx={{
  //               position: "absolute",
  //               width: "calc(100% + 40px)",
  //               zIndex: -1,
  //               // bgcolor: "red",
  //               height: "100%",
  //             }}
  //           >
  //             <img
  //               src={characterMobile[team.character].smog}
  //               style={{ width: "100%", height: "100%" }}
  //             />
  //           </Box>
  //         )}

  //         <Box
  //           sx={{
  //             // bgcolor: "#ff000050",
  //             position: "absolute",
  //             top: "4.95%",
  //             left: "6.4%",
  //             transform: "rotate(0.6deg)",
  //             width: "81.5%",
  //             height: "93.5%",
  //           }}
  //         >
  //           {gameEnded ? (
  //             <>
  //               {showWinner && (
  //                 <Box
  //                   sx={{
  //                     // bgcolor: "#ff000050",
  //                     width: "100%",
  //                     height: "100%",
  //                     position: "relative",
  //                     // display: "flex",
  //                   }}
  //                 >
  //                   <Box
  //                     sx={{
  //                       position: "absolute",
  //                       top: "45%",
  //                       left: "50%",
  //                       transform: "translate(-50%, -50%)",
  //                       width: "60%",
  //                     }}
  //                   >
  //                     <img src={winnerAssets[winner?.character]?.win} />
  //                   </Box>

  //                   <Box
  //                     sx={{
  //                       position: "absolute",
  //                       bottom: "5%",
  //                       left: "50%",
  //                       transform: "translateX(-50%)",
  //                       width: "100%",
  //                     }}
  //                   >
  //                     <Typography
  //                       sx={{
  //                         font: "900 48px Pretendard",
  //                         textAlign: "center",
  //                         color: "#fff",
  //                         WebkitTextStroke: "3px rgb(15, 25, 174)",
  //                         filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25))",
  //                       }}
  //                     >
  //                       {winner?.teamName} 승리
  //                     </Typography>
  //                   </Box>
  //                 </Box>
  //               )}

  //               {!showWinner && (
  //                 <Typography
  //                   sx={{
  //                     position: "absolute",
  //                     fontSize: "clamp(25px, 8.11vw, 36px)",
  //                     fontWeight: "900",
  //                     color: "red",
  //                     top: "50%",
  //                     left: "50%",
  //                     transform: "translate(-50%, -50%)",
  //                     WebkitTextStroke: "1px white",
  //                     filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))",
  //                   }}
  //                 >
  //                   게임 종료!!
  //                 </Typography>
  //               )}
  //             </>
  //           ) : (
  //             <Box
  //               sx={{
  //                 // bgcolor: "#ff000050",
  //                 height: "100%",
  //               }}
  //             >
  //               <Typography
  //                 sx={{
  //                   fontSize: "24px",
  //                   fontWeight: "bold",
  //                   // opacity: "0.7",
  //                   opacity: "0",
  //                   textAlign: "center",
  //                   position: "absolute",
  //                   width: "100%",
  //                 }}
  //               >
  //                 현재 TAP 수: {tapCount}
  //               </Typography>

  //               <Box
  //                 sx={{
  //                   // bgcolor: "#00ff0050",
  //                   position: "absolute",
  //                   top: "53%",
  //                   left: "55%",
  //                   transform: "translate(-50%, -50%) rotate(-0.6deg)",
  //                   width: "85%",
  //                   height: "85%",
  //                   display: "flex",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                 }}
  //               >
  //                 {team?.character && !restMode && (
  //                   <>
  //                     <img src={emphasisGif} style={{ width: "100%", position: "absolute" }} />

  //                     <img
  //                       src={characterMobile[team.character].move}
  //                       // src={tigerRun}
  //                       style={{ width: "100%", position: "absolute" }}
  //                     />
  //                   </>
  //                 )}

  //                 {team?.character && restMode && (
  //                   <img
  //                     src={characterMobile[team.character].rest}
  //                     style={{ width: "100%", position: "absolute" }}
  //                   />
  //                 )}
  //               </Box>

  //               {overlayVisible && (
  //                 <Box
  //                   sx={{
  //                     position: "absolute",
  //                     top: 0,
  //                     left: 0,
  //                     width: "100%",
  //                     height: "100%",
  //                     backgroundColor: "rgba(64, 64, 64, 0.9)",
  //                     zIndex: 2,
  //                     display: "flex",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                     flexDirection: "column",
  //                     color: "#fff",
  //                     font: "bold 32px pretendard",
  //                     boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
  //                     borderRadius: "10px",
  //                     transform: "rotate(-0.6deg)",
  //                   }}
  //                 >
  //                   <Typography
  //                     sx={{
  //                       font: "bold 26px pretendard",
  //                     }}
  //                   >
  //                     열심히 달릴 준비가 되셨나요?
  //                   </Typography>
  //                   <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
  //                     <TimerIcon sx={{ mr: 1, fontSize: "32px" }} />
  //                     <Typography sx={{ fontSize: "32px" }}>{countdown}</Typography>
  //                   </Box>
  //                 </Box>
  //               )}
  //             </Box>
  //           )}
  //         </Box>
  //       </Box>

  //       <Box
  //         sx={{
  //           width: "100%",
  //           display: "flex",
  //           justifyContent: "center",
  //           mt: 4,
  //         }}
  //       >
  //         <Button
  //           sx={{
  //             font: "700 26px Pretendard",
  //             fontSize: "clamp(20.25px, 1.354vw, 28px)",
  //             borderRadius: "10px",
  //             width: "90%",
  //             // height: "100px",
  //             // bgcolor:
  //             //   // tapDisabled || tapCount >= 100 || countdown > 0 || gameEnded
  //             //   tapDisabled || countdown > 0 || gameEnded ? "#A9A9A9" : "#d6e2fb",
  //             bgcolor: "transparent",
  //             color: "#50586C",
  //             pointerEvents:
  //               // tapDisabled || tapCount >= 100 || countdown > 0 || gameEnded ? "none" : "auto",
  //               tapDisabled || countdown > 0 || gameEnded ? "none" : "auto",
  //             "&:hover": {
  //               bgcolor:
  //                 // tapDisabled || tapCount >= 100 || countdown > 0 || gameEnded
  //                 // tapDisabled || countdown > 0 || gameEnded ? "#A9A9A9" : "#c6d2eb",
  //                 "transparent",
  //             },

  //             backgroundImage: `url(${buttonImg})`,
  //             backgroundSize: "cover",
  //             backgroundPosition: "center center",
  //             filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.5))",

  //             aspectRatio: "883/403",
  //             boxShadow: "none",
  //             "&:active": {
  //               transform: "scale(0.95)",
  //             },
  //             "&:disabled": {
  //               bgcolor: "transparent",
  //               opacity: 0.5,
  //             },
  //           }}
  //           disabled={tapDisabled || countdown > 0 || gameEnded}
  //           onClick={handleTapClick}
  //           disableRipple
  //         />
  //         {/* TAP */}
  //         {/* </Button> */}
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <S.Intro>
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography
          sx={{
            font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
            mb: "5px",
            textAlign: "center",
          }}
        >
          {currentLiveTap?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          // bgcolor: "#ff000050",
          width: "100%",
          flex: 1,
          position: "relative",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            // bgcolor: "#ffff0050",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            minHeight: "400px",
          }}
        >
          <Box sx={{ position: "absolute", top: "2.5%", width: "100%" }}>
            <Typography
              sx={{
                textAlign: "center",
                font: "700 clamp(31.2px, 9.9vw, 44px) MapleStoryOTFBold",
                color: "#abc4ff",
                filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3))",
                WebkitTextStroke: "1px #fff",
              }}
            >
              {tapPercent}%
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              aspectRatio: 1,
              bgcolor: "#A6CAEC",
              borderRadius: "50%",
              // overflow: "hidden",
              boxShadow: "0px 3px 6px #00000038",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                background: `conic-gradient(#D86ECC 0%, #D86ECC ${tapPercent}%, #A6CAEC ${tapPercent}%, #A6CAEC 100%)`,
              }}
            />

            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: `url(${background})`,
                backgroundSize: "cover",
                width: "75%",
                aspectRatio: 1,
                borderRadius: "50%",
                bgcolor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!restMode && (
                <img
                  src={focusEffect}
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}

              <img src={Character[team?.teamIdx]} style={{ width: "90%" }} />
            </Box>

            <Typography
              sx={{
                position: "absolute",
                bottom: "-15%",
                left: "50%",
                transform: "translateX(-50%)",
                font: "700 18px Pretendard",
                color: "#33333388",
                width: "max-content",
              }}
            >
              화면을 계속 탭탭!! 연타해보세요!!
            </Typography>
          </Box>

          <Box sx={{ position: "absolute", bottom: "2.5%", width: "100%" }}>
            <Typography
              sx={{
                textAlign: "center",
                font: "600 clamp(34.1px, 10.8vw, 48px) MapleStoryOTFBold",
                color: "#abc4ff",
                filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3))",
                WebkitTextStroke: "1px #fff",
              }}
            >
              Tap!!
            </Typography>
          </Box>

          {overlayVisible && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                color: "#fff",
                borderRadius: "10px",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "rgba(64, 64, 64, 0.9)",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  font: "bold clamp(18.4px, 5.9vw, 26px) pretendard",
                }}
              >
                열심히 달릴 준비가 되셨나요?
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <TimerIcon sx={{ mr: 1, fontSize: "clamp(22.7px, 7.2vw, 32px)" }} />
                <Typography sx={{ fontSize: "clamp(22.7px, 7.2vw, 32px)" }}>{countdown}</Typography>
              </Box>

              {/* // ? 테스트용 코드 (탭 자동화)  */}
              {/* <Box sx={{ position: "absolute", bottom: 0, display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="large"
                  sx={{ color: "white" }}
                  checked={testMode}
                  onChange={() => setTestMode(!testMode)}
                />

                <Typography sx={{ color: "white", font: "700 18px Pretendard" }}>
                  Test Mode
                </Typography>
              </Box> */}
            </Box>
          )}

          {gameEnded && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                color: "#fff",
                borderRadius: "10px",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "rgba(64, 64, 64, 0.9)",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  font: "bold clamp(34.1px, 10.8vw, 48px) MaplestoryOTFBold",
                  color: "#f00",
                  WebkitTextStroke: `1px white`,
                }}
              >
                게임 종료!!
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Modal
        open={!overlayVisible && !gameEnded}
        sx={{ userSelect: "none" }}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "rgba(0, 0, 0, 0)" },
          },
        }}
      >
        <Box
          ref={tapRef}
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#ffffff00",
            outline: "none",
            userSelect: "none",
            "&:active": {
              bgcolor: "#a0a0a033",
            },
          }}
          onClick={handleTapClick}
        />
      </Modal>

      {/* <Temp /> */}
    </S.Intro>
  );
};

export default GameScreen;
