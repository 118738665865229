import React from "react";

import { Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import * as S from "./style";
// import pollingLogo from "assets/images/LiveLandingPageImg/LandingPoll.png";
// import quizLogo from "assets/images/LiveLandingPageImg/LandingQuiz.png";
// import dealLogo from "assets/images/LiveLandingPageImg/LandingDeal.png";
// import pickLogo from "assets/images/LiveLandingPageImg/LandingPick.png";
// import egogramLogo from "assets/images/LiveLandingPageImg/LandingEgogram.png";

export const PaymentTable = () => {
  return (
    <S.TableContainer>
      <S.Title>구독 플랜 특징 비교</S.Title>

      <S.SubTitle>사용 용도에 따라 가장 적합한 플랜을 선택하세요</S.SubTitle>

      <S.Paper>
        <S.Table>
          <S.Head>
            <S.Row>
              <th colSpan={2}>
                <span>플랜</span>
              </th>
              <th>
                <span>무료</span>
              </th>

              <th>
                <span>베이직</span>
              </th>
            </S.Row>
          </S.Head>

          <S.Body>
            <S.Row $bgcolor="#f1f1f1">
              <th rowSpan={3}>
                <div>
                  <S.CellTitle>동시 접속자 수</S.CellTitle>
                </div>
              </th>

              <th>
                <div>
                  {/* <img src={pollingLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Live Polling
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  10명
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  200명
                </div>
              </th>
            </S.Row>

            <S.Row $bgcolor="#f1f1f1">
              <th>
                <div>
                  {/* <img src={quizLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Live Quiz
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  10명
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  200명
                </div>
              </th>
            </S.Row>

            <S.Row $bgcolor="#f1f1f1">
              <th>
                <div>
                  {/* <img src={quizLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Live Board
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  10명
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  200명
                </div>
              </th>
            </S.Row>

            <S.Row>
              <th rowSpan={2} style={{ backgroundColor: "#fff" }}>
                <div>
                  <S.CellTitle>최대 생성 팀</S.CellTitle>
                </div>
              </th>

              <th>
                <div>
                  {/* <img src={pickLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Live Pick
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  3팀
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  8팀
                </div>
              </th>
            </S.Row>

            <S.Row>
              <th>
                <div>
                  {/* <img src={dealLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Live Deal
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  3팀
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  10팀
                </div>
              </th>
            </S.Row>

            <S.Row $bgcolor="#f1f1f1">
              <th>
                <div>
                  <S.CellTitle>
                    생성 가능한 슬롯 <br />
                    및 <br />
                    최대 참가자 수
                    <span style={{ font: "12px Pretendard", color: "#aaa" }}>{" (슬롯당)"}</span>
                  </S.CellTitle>
                </div>
              </th>

              <th>
                <div>
                  {/* <img src={egogramLogo} style={{ width: "50px", margin: "auto" }} /> */}
                  Egogram
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px" }} />
                  <div style={{ lineHeight: "1.5" }}>
                    1개의 슬롯 <br />
                    10명
                  </div>
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "17px", color: "#c33c3c" }} />
                  <div style={{ lineHeight: "1.5" }}>
                    4개의 슬롯 <br />
                    50명
                  </div>
                </div>
              </th>
            </S.Row>

            <S.Row>
              <th rowSpan={2}>
                <div>
                  <S.CellTitle>AI 기능</S.CellTitle>
                </div>
              </th>

              <th>
                <div>
                  Live Polling <br /> <span>클러스터링 기능</span>
                </div>
              </th>

              <th>
                <div className="content">
                  <CloseIcon sx={{ width: "30px" }} />
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "30px", color: "#c33c3c" }} />
                </div>
              </th>
            </S.Row>

            <S.Row>
              <th>
                <div>
                  Live Quiz <br /> <span>문제 자동 생성 기능</span>
                </div>
              </th>

              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "30px" }} />
                  <Typography fontWeight={500}>가입 시 첫 토큰 10개</Typography>
                </div>
              </th>
              <th>
                <div className="content">
                  <CheckIcon sx={{ width: "30px", color: "#c33c3c" }} />
                  <Typography fontWeight={500}>매월 토큰 30개</Typography>
                </div>
              </th>
            </S.Row>
          </S.Body>
        </S.Table>
      </S.Paper>
    </S.TableContainer>
  );
};
