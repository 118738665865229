import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
// import { useContext, useEffect, useState } from "react";
import { useContext } from "react";

import { Box, Typography } from "@mui/material";

import { UserContext, UserProvider } from "../contexts/UserContext";
import * as S from "./styles";

import Intro from "./intro";
import UserInfo from "./userInfo";
import TeamSelect from "./teamSelect";
import WaitingRoom from "./waitingRoom";
import GameScreen from "./gameScreen";
import Result from "./result";

import wliveLogo from "assets/images/wLiveLogo(Red).png";

import sleep from "utils/sleep";

export default () => {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/userInfo" element={<Layout />} />
        <Route path="/teamSelect" element={<Layout />} />
        <Route path="/waitingRoom" element={<Layout />} />
        <Route path="/gameScreen" element={<Layout />} />
        <Route path="/result" element={<Layout />} />
      </Routes>
    </UserProvider>
  );
};

const Layout = () => {
  const { currentLiveTap, teamCount, socket } = useContext(UserContext);

  const { gameId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();

  // 0820 유저가 게임 참여 이력이 있을 때, 유저 정보 넣어서 전달해주도록
  const gotoUserInfo = async () => {
    await sleep(250);

    socket.emit("isGameStart", { gameId, userId: cookies.uid }, (isGameStart, result) => {
      if (isGameStart) {
        if (!result) {
          alert("게임이 진행중이라서 참여할 수 없습니다.");
          return;
        }
        navigate(`/tap/user/${gameId}/gameScreen`, { state: { userInfo: result } });
      } else {
        if (result) {
          navigate(`/tap/user/${gameId}/waitingRoom`);
          return;
        }
        navigate(`/tap/user/${gameId}/userInfo`, { state: { userInfo: result } });
      }
    });
  };
  const gotoTeamSelect = () => {
    navigate(`/tap/user/${gameId}/teamSelect`, { replace: true });
  };
  const gotoWaitingRoom = () => {
    navigate(`/tap/user/${gameId}/waitingRoom`, { replace: true });
  };

  const isUserInfoPage = location.pathname.includes("/userInfo");
  const isTeamSelectPage = location.pathname.endsWith("/teamSelect");
  const isWaitingRoomPage = location.pathname.endsWith("/waitingRoom");
  const isGameScreenPage = location.pathname.endsWith("/gameScreen");
  const isResultPage = location.pathname.endsWith("/result");

  return (
    <S.Body>
      <S.container>
        <S.Header>
          {/* <S.Logo src={wliveLogo} alt="wlive logo" $filter /> */}
          <img src={wliveLogo} alt="wlive logo" style={{ width: "clamp(28.4px, 9vw, 40px)" }} />

          <Typography
            sx={{
              font: "700 clamp(17px, 5.41vw, 24px) Pretendard",
              ml: "clamp(7.1px, 2.25vw, 10px)",
              color: "#333",
            }}
          >
            Withplus Live - 라이브 탭
          </Typography>
        </S.Header>

        <Box sx={{ flex: 1 }}>
          {/* {isUserInfoPage ? (
            <UserInfo gotoTeamSelect={gotoTeamSelect} gotoWaitingRoom={gotoWaitingRoom} />
          ) : isTeamSelectPage ? (
            <TeamSelect gotoWaitingRoom={gotoWaitingRoom} />
          ) : isWaitingRoomPage ? (
            <WaitingRoom />
          ) : isGameScreenPage ? (
            <GameScreen />
          ) : (
            <Intro gotoUserInfo={gotoUserInfo} />
          )} */}
          {(() => {
            if (isUserInfoPage)
              return <UserInfo gotoTeamSelect={gotoTeamSelect} gotoWaitingRoom={gotoWaitingRoom} />;
            if (isTeamSelectPage) return <TeamSelect gotoWaitingRoom={gotoWaitingRoom} />;
            if (isWaitingRoomPage) return <WaitingRoom />;
            if (isGameScreenPage) return <GameScreen />;
            if (isResultPage) return <Result />;
            return <Intro gotoUserInfo={gotoUserInfo} />;
          })()}
        </Box>

        <S.Footer>
          <Typography sx={{ font: "400 14px Pretendard", ml: "10px" }}>
            Copyright ⓒ Withplus. All Right Reserved
          </Typography>
        </S.Footer>
      </S.container>
    </S.Body>
  );
};
