import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

import CheckIcon from "@mui/icons-material/Check";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 170px;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font: 700 30px Pretendard;
  color: #343434;
  text-align: center;
`;

export const SubTitle = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font: 300 16px Pretendard;
  text-align: center;
`;

export const Paper = styled.div`
  width: 885px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 700px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Head = styled.thead`
  width: 100%;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;

  > tr > th {
    width: 33%;
    > span {
      font: bold 24px Pretendard;
    }
  }
`;

export const Body = styled.tbody`
  > tr:nth-child(-n + 3),
  > tr:last-child {
    // background-color: #f1f1f1;
  }

  > tr > th {
    border-bottom: 1px solid #e0e0e0;
  }

  > tr > th > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  > tr > th > div.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: 400 17px Pretendard;
    color: #343434;
  }
`;

export const Row = styled.tr`
  // background-color: #fff;
  background-color: ${(props) => props.$bgcolor || "#fff"};
  > td,
  th {
    text-align: center;
    padding: 30px 0px;
  }
`;

export const CellTitle = styled.div`
  font: 700 18px Pretendard;
  line-height: 1.5;
  color: #343434;
`;

export const GamesBox = styled.div`
  font: 400 14px Pretendard;
  color: #808080;
`;

// export const Check = MuiStyled(CheckIcon)({
//   color: "green",
//   filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))",
// });
