import React, { useEffect, useState } from "react";

import { Box, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const DuplicateQuizModal = ({ open, onClose, targetId, duplicateQuiz }) => {
  const [loading, setLoading] = useState(false);

  const action = () => {
    setLoading(true);
    duplicateQuiz(targetId);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ bgcolor: "#fff", p: "20px", borderRadius: "10px", color: "#333" }}>
        <Typography sx={{ font: "700 28px Pretendard", mb: "10px" }}>
          해당 퀴즈를 복제하시겠습니까?
        </Typography>

        <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
          기존 퀴즈의 이미지 용량 및 개수에 따라 복제하는데 시간이 소요될 수 있습니다.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", pt: "10px" }}>
          <LoadingButton
            variant="contained"
            sx={{
              font: "600 16px Pretendard",
              padding: "10px 20px",
              bgcolor: "#909090",
              "&:hover": {
                bgcolor: "#707070",
              },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            variant="contained"
            sx={{
              padding: "10px 20px",
              font: "600 16px Pretendard",
            }}
            loading={loading}
            onClick={action}
          >
            복제
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
