import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

import { Box, Button, Typography, Grid } from "@mui/material";
// import WavingHandIcon from "@mui/icons-material/WavingHand";

import * as S from "./styles";

// import postItElephant from "assets/images/LiveTap/post-it-elephant.png";
import background from "assets/images/LiveTap_new/background_start.webp";
import Character from "assets/images/LiveTap_new/Character";

import { UserContext } from "../contexts/UserContext";

const TeamSelect = (props) => {
  const navigate = useNavigate();
  const { teamCount, socket, currentLiveTap, name } = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { gameId } = useParams();
  const [cookies, setCookie] = useCookies();

  const joinTeama = () => {
    socket.emit(
      "joinTeam",
      { gameId, userId: cookies.uid, teamId: selectedTeam, userName: name },
      ({ success }) => {
        if (success) {
          props.gotoWaitingRoom();
        }
      }
    );
  };

  const joinTeam = (e) => {
    setSelectedTeam(e.currentTarget.id);
    // console.log(`선택한 팀 ID: ${e.currentTarget.id}`);
    const selectedTeam = e.currentTarget.id;

    let userId = cookies.uid;

    if (!userId) {
      const maxAge = 60 * 60 * 24 * 365;
      userId = uuidv4();
      setCookie("uid", userId, { path: "/", maxAge });
    }

    socket.emit(
      "joinTeam",
      { gameId, userId, teamId: selectedTeam, userName: name },
      ({ success, message }) => {
        if (success) {
          setTimeout(() => {
            props.gotoWaitingRoom();
          }, 250);
        } else {
          alert(message);
          window.location.href = "/";
        }
      }
    );
  };

  // Determine the layout based on the number of teams
  const isGridLayout = teamCount === 4;

  return (
    <S.Intro>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
            mb: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentLiveTap?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "clamp(7.1px, 2.3vw, 10px)",
        }}
      >
        <Typography sx={{ font: "700 clamp(17px, 5.4vw, 24px) Pretendard", mb: 3 }}>
          참여 할 팀을 선택해주세요
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "1/0.8",
            backgroundImage: `url(${background})`,
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            overflow: "hidden",
            // transform: "rotate(5deg)",
          }}
        >
          <Grid
            container
            sx={{
              bgcolor: "#ffffff50",
              backdropFilter: "blur(5px)",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {currentLiveTap?.teamData.map((item, index) => (
              <Grid
                item
                key={index}
                xs={6}
                sx={{
                  height: "50%",
                  // position: "relative",
                  p: "clamp(7.1px, 2.3vw, 10px)",
                }}
              >
                <Button
                  variant={selectedTeam === item.id ? "contained" : "outlined"}
                  onClick={joinTeam}
                  id={item.id}
                  sx={{
                    // width: isGridLayout ? "42%" : "80%", // Full width for column layout, half width for grid
                    position: "relative",
                    width: "100%", // Full width for column layout, half width for grid
                    height: "100%",
                    borderRadius: "10px",
                    padding: "clamp(7.1px, 2.3vw, 10px)",
                    textAlign: "center",
                    border: "3px solid #c33c3c",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    // bgcolor: "#ddd",
                    transform: selectedTeam === item.id ? "scale(1)" : "scale(0.8)",
                    transition: "transform 0.3s ease",

                    // marginBottom: isGridLayout ? "10px" : "0", // Ensure proper spacing between rows in grid
                  }}
                >
                  <Typography
                    sx={{
                      font: "800 clamp(15.6px, 5vw, 22px) Pretendard",
                    }}
                  >
                    <span style={{ font: "900 clamp(28.4px, 9vw, 40px) Pretendard" }}>
                      {index + 1}
                    </span>
                    {" 팀"}
                  </Typography>

                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: "-20%",
                      left: "-20%",
                      width: "60%",
                    }}
                  >
                    <img src={Character[index]} alt="Character" />
                  </Box>
                </Button>
              </Grid>
            ))}
          </Grid>

          {/* <Box
            sx={{
              // transform: "rotate(-5deg)",
              // display: "flex",
              display: "none",
              // flexDirection: isGridLayout ? "row" : "column",
              // flexWrap: isGridLayout ? "wrap" : "nowrap",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // Space between buttons
              width: "100%",
              height: "100%",
              // ml: "15px",
              // mt: "15px",
              bgcolor: "#ffffff50",
              backdropFilter: "blur(5px)",
            }}
          >
            {currentLiveTap?.teamData.map((item, index) => (
              <Button
                key={index}
                variant={selectedTeam === item.id ? "contained" : "outlined"}
                onClick={handleTeamSelect}
                id={item.id}
                sx={{
                  // width: isGridLayout ? "42%" : "80%", // Full width for column layout, half width for grid
                  width: "42%", // Full width for column layout, half width for grid
                  borderRadius: "10px",
                  font: "bold 22px Pretendard",
                  padding: "10px",
                  textAlign: "center",
                  marginBottom: isGridLayout ? "10px" : "0", // Ensure proper spacing between rows in grid
                }}
              >
                {`${index + 1}팀`}
              </Button>
            ))}
          </Box> */}
        </Box>

        {/* <Button
          variant="contained"
          onClick={joinTeam}
          disabled={!selectedTeam}
          size="large"
          sx={{
            mt: "15px",
            font: "700 clamp(14.2px, 4.5vw, 20px) Pretendard",
            p: "10px clamp(14.2px, 4.5vw, 20px)",
            bgcolor: "#d6e2fb",
            color: "#50586C",
            "&:hover": { bgcolor: "#c6d2eb" },
            boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
            animation: selectedTeam && "clickMe 2s infinite",

            "@keyframes clickMe": {
              "0%": { transform: "scale(1)" },
              "12.5%": { transform: "scale(1.1)" },
              "37.5%": { transform: "scale(1)" },
            },
          }}
        >
          <WavingHandIcon sx={{ mr: "clamp(7.1px, 2.3vw, 10px)" }} />
          선택 완료
        </Button> */}
      </Box>
    </S.Intro>
  );
};

export default TeamSelect;
