import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Modal,
  CircularProgress,
  Checkbox,
} from "@mui/material";

// import Title from "components/Title";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from "@mui/icons-material/Help";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ErrorIcon from "@mui/icons-material/Error";

import logo from "assets/images/wLiveLogo(Red).png";
import folder from "assets/images/Icon/folder/folder9.png";
import { LoadingButton } from "@mui/lab";
import { Textfield } from "components/Textfield";
import { QuizTemplate } from "template/Quiz";
import { QuizContext } from "views/LiveQuiz/contexts/Quiz";
import { SnackBarContext } from "views/LiveQuiz/contexts/SnackBar";
import { useCookies } from "react-cookie";
import {
  GET_Chat,
  GET_generateQuiz,
  GET_tokens,
  POST_generateQuiz,
  POST_SaveChat,
} from "utils/api/Quiz";

const EditPageLayout = (props) => {
  const { children, action } = props;
  // const { id } = useParams();

  const [aiModalOpen, setAiModalOpen] = useState(false);

  const handleAiModal = () => {
    setAiModalOpen(!aiModalOpen);
  };

  // // 테스트용
  // const [idx, setIdx] = useState(0);

  // const first = () => {
  //   window.location.href = `/features/quiz/edit/${list[0]}`;
  // };

  // const next = () => {
  //   const idx = list.indexOf(id);

  //   if (idx === list.length - 1) {
  //     alert("마지막 페이지입니다.");
  //     return;
  //   }

  //   window.location.href = `/features/quiz/edit/${list[idx + 1]}`;

  //   // console.log(list);
  //   // console.log(id);
  // };

  // useEffect(() => {
  //   let idx = list.indexOf(id);

  //   setIdx(idx);
  // }, []);
  // // 테스트용 end

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          minWidth: "1400px",
          maxWidth: "1920px",
          maxHeight: "1080px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0 3px 6px #00000029",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              p: "12.5px",
              opacity: "0.75",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <Typography sx={{ font: "700 20px Pretendard" }}>
              Withplus Live - 라이브 퀴즈
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* 테스트용 */}
            {/* {localStorage.getItem("UUID_ADM") && (
              <>
                <Box>
                  {idx + 1} / {list.length}
                </Box>
                <Button variant="contained" onClick={first}>
                  처음으로
                </Button>
                <Button variant="contained" onClick={next}>
                  다음
                </Button>
              </>
            )} */}
            {/* 테스트용 */}

            <Tooltip title="도움말" arrow>
              <IconButton
                onClick={() => {
                  window.open(
                    "https://withplus-live.notion.site/Live-Quiz-d7632fc9964a4eb7afb024887706264b",
                    "_blank",
                    "width=600, height=800"
                  );
                }}
              >
                <HelpIcon fontSize="large" sx={{ color: "#999" }} />
              </IconButton>
            </Tooltip>

            <LoadingButton
              sx={{
                p: "7.5px, 10px",
                color: "#00CED1",
                border: "2px solid #00CED1",
                boxShadow: "0 3px 6px #00000029",
                bgcolor: "#fff",

                "&:active": {
                  boxShadow: "none",
                  transform: "translateY(1px)",
                },
                "&:disabled": {
                  bgcolor: "#e0e0e0",
                  border: "2px solid #999",
                },
              }}
              onClick={handleAiModal}
            >
              <AutoFixHighIcon />

              <Typography sx={{ font: "700 16px Pretendard", pl: "10px" }}>
                AI로 문제 생성
              </Typography>
            </LoadingButton>

            <Button
              variant="contained"
              sx={{
                display: "flex",
                gap: "5px",
                height: "auto",
                font: "700 18px Pretendard",
                p: "5px",
              }}
              onClick={action}
            >
              <SaveIcon fontSize="large" />
              저장하기
            </Button>
          </Box>
        </Box>

        <Box
          className="paper"
          children={children.content}
          sx={{ flex: 1, m: "10px", boxSizing: "border-box", overflow: "hidden" }}
        />
      </Box>

      <AiModal open={aiModalOpen} onClose={handleAiModal} />
    </Box>
  );
};

const AiModal = (props) => {
  const { open, onClose } = props;

  const { slideList, setSlideList } = useContext(QuizContext);
  const { setOpen, setSeverity, setText: setSnackBarText } = useContext(SnackBarContext);

  const { id } = useParams();
  const inputRef = useRef(null);
  const chatRef = useRef(null);

  const [cookies] = useCookies();

  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [chats, setChats] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [helperText, setHelperText] = useState("");

  const [chatBtnLoading, setChatBtnLoading] = useState(false);
  const [generateBtnLoading, setGenerateBtnLoading] = useState(false);
  const [images, setImages] = useState([]);

  const [token, setToken] = useState(0);

  const handleText = (e) => {
    setText(e.target.value);
  };

  const handleFile = (e) => {
    const fetchImage = (url) => {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);

            resolve({ url, blob });
          });
      });
    };

    setImages([]);

    if (!e.target.files.length) return;

    let file = e.target.files[0];

    if (!file || file.size > 10 * 1024 * 1024) {
      alert("10MB 이하의 파일만 업로드 가능합니다.");
      return;
    }

    const formData = new FormData();

    formData.append("file", file);

    setChatBtnLoading(true);
    setHelperText("파일을 업로드 중입니다. 잠시만 기다려주세요.");

    axios
      .post(`https://pyapi.withplus.live/convert-pdf-to-images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        const { images } = res.data;

        if (images.length === 0) {
          alert("PDF 파일을 업로드해주세요.");
          setChatBtnLoading(false);
          return;
        }

        let imageList = [];

        for (let i = 0; i < images.length; i++) {
          let image = await fetchImage(images[i]);
          imageList.push(image);
          fetch(images[i], {
            method: "DELETE",
          });
        }

        setFile(file);
        setImages(imageList);
        setChatBtnLoading(false);
        setText(file.name);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setHelperText("");
        setChatBtnLoading(false);
      });
  };

  const handleChat = async () => {
    setChats([
      ...chats,
      { role: "user", text, file: Boolean(file) },
      { role: "system", loading: true },
    ]);

    for (let i = 0; i < images.length; i++) {
      const formData = new FormData();

      formData.append("file", images[i].blob);

      // await axios
      //   .post(`/quiz/generate?name=${id + i}.webp`, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      await POST_generateQuiz(id + i, formData)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    }

    setChatBtnLoading(true);

    // axios
    //   .get(`/quiz/generate?gameId=${id}&text=${images.length !== 0 ? "" : text}`)
    let chatText = images.length !== 0 ? "" : text;

    GET_generateQuiz(id, chatText, cookies.auth)
      .then((res) => {
        let { success, result, message } = res.data;

        if (!success) {
          if (message === "포인트가 부족합니다.") {
            let newChats = [
              ...chats,
              { role: "user", text, file: Boolean(file) },
              {
                role: "system",
                loading: false,
                text: "포인트가 부족합니다.",
                error: true,
              },
            ];

            setChats(newChats);
            saveChat(newChats);
          } else {
            let newChats = [
              ...chats,
              { role: "user", text, file: Boolean(file) },
              {
                role: "system",
                loading: false,
                text: "문제 생성에 실패했습니다. 다시 시도해주세요.",
                error: true,
              },
            ];

            setChats(newChats);
            saveChat(newChats);
          }
        } else {
          let newList = [];

          for (let i = 0; i < result.list.length; i++) {
            newList.push({
              ...result.list[i],
              id: uuidv4(),
            });
          }

          let newChats = [
            ...chats,
            { role: "user", text, file: Boolean(file) },
            {
              role: "system",
              list: newList,
            },
          ];

          setChats(newChats);
          saveChat(newChats);

          setQuestions([...questions, ...newList]);
        }

        setChatBtnLoading(false);
        getTokens();
      })
      .catch((err) => {
        let newChats = [
          ...chats,
          { role: "user", text, file: Boolean(file) },
          {
            role: "system",
            loading: false,
            text: "문제 생성에 실패했습니다. 다시 시도해주세요.",
            error: true,
          },
        ];

        setChats(newChats);
        saveChat(newChats);
        setChatBtnLoading(false);
      });

    setText("");
    setFile(null);
    setImages([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  const saveChat = (newChats) => {
    POST_SaveChat(id, newChats)
      .then((res) => {
        const { success } = res.data;

        if (!success) {
          console.log("채팅을 저장하는데 실패했습니다");
        }
      })
      .catch((err) => {
        console.log("채팅을 저장하는데 실패했습니다");
        console.log(err);
      });
  };

  const generateQuestions = async () => {
    setGenerateBtnLoading(true);

    let newSlideList = [];

    for (let id of selectedQuestions) {
      let question = questions.find((item) => item.id === id);

      let newQuestion = QuizTemplate(question.type);
      newQuestion.question = question.question;

      switch (question.type) {
        case "객관식":
          newQuestion.answers = [];

          for (let option of question.options) {
            let id = uuidv4();
            newQuestion.answers.push({ id, text: option });

            if (option === question.answer) newQuestion.correctAnswerIDs.push(id);
          }
          break;

        case "OX 퀴즈":
          newQuestion.answer = question.answer;
          break;

        default:
          break;
      }

      newQuestion.isCompleted = true;

      newSlideList.push(newQuestion);
    }

    setSlideList([...slideList, ...newSlideList]);

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    await sleep(500);

    setGenerateBtnLoading(false);

    setOpen(true);
    setSeverity("success");
    setSnackBarText("문제가 생성되었습니다.");

    setSelectedQuestions([]);

    onClose();
  };

  const getTokens = () => {
    // axios
    //   .get(`/quiz/token?auth=${cookies.auth}`)
    GET_tokens(cookies.auth)
      .then((res) => {
        const { success, token } = res.data;

        if (!success) {
          alert("토큰을 불러오는데 실패했습니다.");

          window.location.reload();
          return;
        }

        setToken(token);
      })
      .catch((err) => {
        console.log(err);
        alert("토큰을 불러오는데 실패했습니다.");

        window.location.reload();
      });
  };

  useEffect(() => {
    const scrollDown = () => {
      if (!chatRef.current) return;

      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    };

    scrollDown();
  }, [chats]);

  useEffect(() => {
    if (open) {
      getTokens();
    }
  }, [open]);

  useEffect(() => {
    GET_Chat(id)
      .then((res) => {
        const { success, chat } = res.data;

        if (!success) {
          console.log("채팅을 불러오는데 실패했습니다.");

          return;
        }

        setChats(chat);

        let newQuestions = [];

        for (let item of chat) {
          if (item.list) {
            newQuestions = [...newQuestions, ...item.list];
          }
        }

        setQuestions(newQuestions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: "10px" }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "10px",
          width: "1024px",
          maxHeight: "768px",
          height: "100%",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ p: "10px", position: "relative" }}>
            <Typography sx={{ font: "700 26px Pretendard", textAlign: "center", color: "#333" }}>
              AI로 문제 생성하기
            </Typography>

            <Typography
              sx={{
                font: "700 16px Pretendard",
                textAlign: "center",
                color: "#fff",
                bgcolor: "#2e508d",
                margin: "auto",
                p: "5px 10px",
                position: "absolute",
                top: "10px",
                borderRadius: "6px",
              }}
            >
              나의 남은 문제 생성 토큰 갯수 : {token}개
            </Typography>
          </Box>

          <IconButton sx={{ position: "absolute", top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ px: "20px" }}>
            <Typography sx={{ font: "600 15px Pretendard", color: "#f00", lineHeight: "1.5" }}>
              * PDF로 문제 생성은 3토큰 / 텍스트로 문제 생성은 1토큰이 차감됩니다.
            </Typography>

            <Typography sx={{ font: "600 14px Pretendard", color: "#c33c3c", lineHeight: "1.5" }}>
              * 파일은 PDF 파일만 첨부 가능하고, 10MB 이하, 10장 이하의 페이지로 문제가 생성됩니다.
            </Typography>

            <Typography sx={{ font: "600 14px Pretendard", color: "#c33c3c", lineHeight: "1.5" }}>
              * 페이지가 10장 이상인 경우, 첫 페이지부터 10장까지 내에서만 문제가 생성됩니다. (최대
              30문제)
            </Typography>
          </Box>
          <Box
            sx={{
              p: "10px",
              pt: 0,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Textfield
              size="small"
              multiline
              maxRows={5}
              fullWidth
              value={text}
              onChange={handleText}
              disabled={file || chatBtnLoading}
              helperText={helperText}
              InputProps={{
                startAdornment: (
                  <Tooltip title="PDF 파일 첨부" arrow placement="top">
                    <IconButton
                      sx={{ color: "#333" }}
                      onClick={() => inputRef.current.click()}
                      disabled={chatBtnLoading}
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip>
                ),
                endAdornment: (
                  <LoadingButton
                    sx={{
                      color: "#333",
                      bgcolor: "#fff",
                      boxShadow: "0 3px 6px #00000029",
                      borderRadius: "50%",
                      p: 0,
                      m: 0,
                      width: "40px",
                      height: "40px",
                      minWidth: "40px",

                      "&:disabled": {
                        boxShadow: "none",
                        border: "1px solid #ddd",
                        bgcolor: "#e0e0e0",
                      },
                    }}
                    disabled={!text && !file}
                    loading={chatBtnLoading}
                    onClick={handleChat}
                  >
                    <ArrowDownwardIcon />
                  </LoadingButton>
                ),
              }}
              placeholder="PDF파일을 업로드 하거나, AI로 생성할 문제 주제를 자세하게 입력해주세요."
            />

            {file && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  bgcolor: "#eee",
                  pl: "30px",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="파일 제거" arrow>
                  <IconButton
                    sx={{ color: "#333" }}
                    onClick={() => {
                      setFile(null);
                      setImages([]);
                      if (inputRef.current) inputRef.current.value = "";
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {images.length !== 0 && (
              <Box
                sx={{
                  p: "10px",
                  pt: 0,
                  bgcolor: "#eee",
                  display: "flex",
                  gap: "20px",
                  overflow: "auto",
                  boxSizing: "border-box",
                }}
              >
                {images.map((image, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: "150px",
                      flexShrink: 0,
                    }}
                  >
                    <img src={image.url} alt={image.url} style={{ width: "100%" }} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box sx={{ flex: 1, position: "relative" }}>
            <Box
              ref={chatRef}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "auto",
                p: "10px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              {chats.map((chat, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    justifyContent: chat.role === "system" ? "flex-start" : "flex-end",
                    p: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      p: "10px",
                      bgcolor: chat.role === "user" ? "#f5f5f5" : "#ddffff",
                      borderRadius: "4px",
                      position: "relative",
                      filter: "drop-shadow(2px 3px 3px #00000038)",
                      maxWidth: "70%",

                      "&::after":
                        chat.role === "system"
                          ? {
                              content: '""',
                              position: "absolute",
                              left: "-20px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              borderWidth: "10px",
                              borderStyle: "solid",
                              borderColor: "transparent #ddffff transparent transparent",
                            }
                          : {
                              content: '""',
                              position: "absolute",
                              right: "-20px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              borderWidth: "10px",
                              borderStyle: "solid",
                              borderColor: "transparent transparent transparent #f5f5f5",
                            },
                    }}
                  >
                    {chat.file && <AttachFileIcon />}

                    {chat.error && <ErrorIcon sx={{ color: "#ff0000d0" }} />}

                    {chat.text && (
                      <Box>
                        {chat.text.split("\n").map((line, idx) => (
                          <Typography
                            key={idx}
                            sx={{
                              display: "block",
                              font: "600 16px Pretendard",
                              color: chat.error ? "#ff0000d0" : "#333",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {chat.list && (
                      <Box sx={{ overflow: "hidden", position: "relative" }}>
                        {/* <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                          <Button variant="contained" sx={{ font: "600 15px Pretendard" }} >
                            전체 선택 / 전체 해제
                          </Button>
                        </Box> */}

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: "20px",
                          }}
                        >
                          <img
                            src={folder}
                            alt="folder"
                            style={{ width: "30px", height: "30px" }}
                          />

                          <Typography
                            sx={{
                              font: "600 18px Pretendard",
                              color: "#333",
                              ml: "10px",
                            }}
                          >
                            {chat.list.length}개의 문제가 생성되었습니다.
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                            overflow: "auto",
                            pb: "10px",
                            overscrollBehavior: "none",
                          }}
                          onWheel={(e) => {
                            e.currentTarget.scrollLeft += e.deltaY;
                          }}
                        >
                          {chat.list.map((item, idx) => (
                            <QuizCard
                              key={idx}
                              item={item}
                              selectedQuestions={selectedQuestions}
                              setSelectedQuestions={setSelectedQuestions}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}

                    {chat.loading && (
                      <Box
                        sx={{
                          width: "200px",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            font: "600 14px Pretendard",
                            color: "#777",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        >
                          문제 생성중...
                        </Typography>
                        <CircularProgress sx={{ color: "#c33c3c88" }} />
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: "10px",
              pt: "5px",
              borderTop: "1px solid #ddd",
              mt: "5px",
            }}
          >
            {selectedQuestions.length !== 0 && (
              <LoadingButton
                variant="contained"
                onClick={generateQuestions}
                loading={generateBtnLoading}
              >
                <Typography sx={{ font: "600 16px Pretendard" }}>
                  선택한 {selectedQuestions.length}개 문제 생성하기
                </Typography>
              </LoadingButton>
            )}
          </Box>
        </Box>

        <input
          type="file"
          accept=".pdf"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFile}
        />
      </Box>
    </Modal>
  );
};

const QuizCard = React.memo(({ item, selectedQuestions, setSelectedQuestions }) => {
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        p: "10px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        position: "relative",
        minWidth: "150px",
        maxWidth: "300px",
        boxShadow: "0 3px 6px #00000029",
      }}
    >
      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <Checkbox
          size="small"
          checked={selectedQuestions.includes(item.id)}
          onChange={(e) => {
            setSelectedQuestions(
              e.target.checked
                ? [...selectedQuestions, item.id]
                : selectedQuestions.filter((id) => id !== item.id)
            );
          }}
        />
      </Box>

      <Box sx={{ mb: "5px" }}>
        <Typography sx={{ font: "700 16px Pretendard" }}>{item.type}</Typography>
      </Box>

      <Box sx={{ mb: "5px", flex: 1 }}>
        <Typography
          sx={{
            font: "600 16px Pretendard",
            mb: "5px",
            wordBreak: "keep-all",
            borderBottom: "1px solid #ddd",
            pb: "5px",
          }}
        >
          {item.question}
        </Typography>

        {item.options?.map((option, idx) => (
          <Typography key={idx} sx={{ font: "500 14px Pretendard" }}>
            {idx + 1}. {option}
          </Typography>
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography sx={{ font: "600 16px Pretendard" }}>정답: {item.answer}</Typography>
      </Box>
    </Box>
  );
});

export default EditPageLayout;
