import { Box, Typography } from "@mui/material";
import * as S from "./styles";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { useParams } from "react-router-dom";

// import { CustomButton as Button } from "../../../components/Button";
// import TimerIcon from "@mui/icons-material/Timer";
// import { useCookies } from "react-cookie";
// import { useNavigate, useLocation, useParams } from "react-router-dom";

import characterMobile from "assets/images/LiveTap/characterMobile";

// import buttonImg from "assets/images/LiveTap/button.png";
// import memoMobile from "assets/images/LiveTap/memoMobile.png";
// import emphasisGif from "assets/images/LiveTap/characterMobile/emphasis.gif";
// import winnerAssets from "assets/images/LiveTap/winnerManager";
// import background from "assets/images/LiveTap_new/background_start.png";
import background from "assets/images/LiveTap_new/background_start.webp";
import Character_Win from "assets/images/LiveTap_new/Character_Win";

// import Hippo from "assets/images/LiveTap/teamCharacter/1.png";
// import Cow from "assets/images/LiveTap/teamCharacter/2.png";
// import Tiger from "assets/images/LiveTap/teamCharacter/3.png";
// import Elephant from "assets/images/LiveTap/teamCharacter/4.png";

const GameScreen = () => {
  const { gameId } = useParams();
  const { currentLiveTap, team } = useContext(UserContext);

  const boxRef = useRef(null);
  const backgroundRef = useRef(null);

  const [myTeam, setMyTeam] = useState(null);
  const [myRank, setMyRank] = useState(null);

  const getImageByTeamName = (teamName) => {
    switch (teamName) {
      case "1 팀":
        // return Hippo;
        return Character_Win[0];
      case "2 팀":
        // return Cow;
        return Character_Win[1];
      case "3 팀":
        // return Tiger;
        return Character_Win[2];
      case "4 팀":
        // return Elephant;
        return Character_Win[3];
      default:
        return null;
    }
  };

  const getBgColor = (rank) => {
    if (rank === 1) return "rgb(246, 117, 103)";
    if (rank === 2) return "rgb(150, 225, 235)";
    if (rank === 3) return "rgb(177, 202, 255)";
    return "#777";
  };

  useEffect(() => {
    if (!currentLiveTap || !team) return;

    // if (!currentLiveTap.isGameEnded) {
    //   if (!currentLiveTap.isGameStart) {
    //     alert("게임이 시작되지 않았습니다.");
    //   } else {
    //     alert("게임이 종료되지 않았습니다.");
    //   }

    //   window.location.href = `/tap/user/${gameId}`;
    // }

    if (currentLiveTap.gameMode === "team") {
      let teams = currentLiveTap.teamData;

      teams.forEach((team) => {
        team.totalTapCount =
          team.teamMembers.reduce((acc, cur) => acc + cur.tapCount, 0) / team.teamScore;
      });

      teams.sort((a, b) => b.totalTapCount - a.totalTapCount);

      let myIdx = teams.findIndex((t) => t.id === team.id);

      setMyRank(myIdx + 1);
      setMyTeam({
        ...team,
        character: getImageByTeamName(team.teamName),
        rank: myIdx + 1,
        bgColor: getBgColor(myIdx + 1),
      });
    }
  }, [currentLiveTap, team]);

  // useEffect(() => {
  //   console.log(myTeam);
  // }, [myTeam]);

  const Temp = () => {
    return (
      <Box
        sx={{
          width: "100%",
          font: "bold 22px pretendard",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          position: "relative",
          zIndex: 1,
          aspectRatio: "1573/1874",
          // bgcolor: "#ff000050",
        }}
      >
        <img
          // src={memoMobile}
          style={{
            position: "absolute",
            width: "100%",
            filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25))",
          }}
        />

        {team?.character && (
          <img
            src={characterMobile[team.character].tape}
            style={{ position: "absolute", width: "90%", zIndex: 1, top: "-2.5%" }}
          />
        )}

        {team?.character && (
          <Box
            sx={{
              position: "absolute",
              width: "calc(100% + 40px)",
              zIndex: -1,
              // bgcolor: "red",
              height: "100%",
            }}
          >
            <img
              src={characterMobile[team.character].smog}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        )}

        <Box
          sx={{
            //   bgcolor: "#ffff0050",
            position: "absolute",
            top: "4.95%",
            left: "6.4%",
            transform: "rotate(0.6deg)",
            width: "81.5%",
            height: "93.5%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "7.5%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                font: "bold 30px pretendard",
                px: "10px",
                textAlign: "center",
                color: "#333",
                bgcolor: "#fff",
                fontSize: "clamp(21px, 6.756vw, 30px)",
              }}
            >
              내 팀의 최종 결과
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "5%",
              width: "100%",
              // bgcolor: "#ff000050",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                p: "5px",
                pb: 0,
                display: "flex",
                flexDirection: "column",
                //   alignItems: "center",
                gap: "10px",
                width: "50%",
              }}
            >
              <Box sx={{ width: "30%", margin: "0 auto" }}>
                <img
                  src={myTeam?.character}
                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
              </Box>

              <Typography
                sx={{
                  font: "700 24px Pretendard",
                  textAlign: "center",
                  bgcolor: "#fff",
                  width: "max-content",
                  px: "10px",
                  margin: "0 auto",
                }}
              >
                {myTeam?.teamName}
              </Typography>

              <Box
                sx={{
                  bgcolor: myTeam?.bgColor || "#777",
                  borderRadius: "10px 10px 0 0 ",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  aspectRatio: `1 / ${0.9 - (myTeam?.rank - 1) * 0.2}`,
                }}
              >
                <Typography
                  sx={{
                    font: "900 42px Pretendard",
                    textAlign: "center",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                    fontSize: "clamp(29px, 9.459vw, 42px)",
                  }}
                >
                  {myTeam?.rank}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <S.Intro>
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography sx={{ font: "bold 20px Pretendard", mb: "5px", textAlign: "center" }}>
          {currentLiveTap?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            // bgcolor: "#ffff0050",
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                font: "800 40px Pretendard",
                px: "10px",
                textAlign: "center",
                color: "#333",
                WebkitTextStroke: "1px #fff",
              }}
            >
              내 팀의 최종 결과
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%,-50%) scale(1)`,
              bgcolor: "#ff000050",
              width: "100%",
              aspectRatio: "1/0.9",
              backgroundImage: `url(${background})`,
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                // bgcolor: "#ff000050",
                position: "absolute",
                bottom: "5%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "30%" }}>
                <img src={myTeam?.character} />
              </Box>

              <Typography
                sx={{
                  font: "700 33px MapleStoryOTFBold",
                  textAlign: "center",
                  width: "max-content",
                  px: "10px",
                  margin: "0 auto",
                  WebkitTextStroke: "1px #fff",
                }}
              >
                {myTeam?.teamName}
              </Typography>

              <Box
                sx={{
                  bgcolor: myTeam?.bgColor || "#777",
                  borderRadius: "10px 10px 0 0 ",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30%",
                  aspectRatio: `1 / ${0.9 - (myTeam?.rank - 1) * 0.2}`,
                  boxShadow: "3px 3px 3px rgba(0,0,0,0.3)",
                }}
              >
                <Typography
                  sx={{
                    font: "900 42px Pretendard",
                    textAlign: "center",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                    fontSize: "clamp(29px, 9.459vw, 42px)",
                  }}
                >
                  {myTeam?.rank}등
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </S.Intro>
  );
};

export default GameScreen;
