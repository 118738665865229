import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import axios from "axios";
import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// // replace console.* for disable log on production
// if (process.env.NODE_ENV === "production") {
//   console.log = () => {};
//   console.debug = () => {};

//   // 에러 발생 시 서버로 전송
//   window.onerror = function (msg, url, lineNo, columnNo, error) {
//     const errorInfo = {
//       msg,
//       url,
//       lineNo,
//       columnNo,
//       error,
//       location: window.location.href,
//     };

//     console.error(errorInfo);
//     // 서버로 전송
//     axios.post("/api/error", errorInfo);
//   };
// } else {
//   // 에러 발생 시 서버로 전송
//   window.onerror = function (msg, url, lineNo, columnNo, error) {
//     const errorInfo = {
//       msg,
//       url,
//       lineNo,
//       columnNo,
//       error,
//     };

//     console.error(errorInfo);
//     // 서버로 전송
//     axios.post("/api/error", errorInfo);
//   };
// }

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
);
